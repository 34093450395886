<template>
    <svg :width="size" :height="size" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M133.299 115.5C138.108 115.5 141.932 112.474 145.365 108.244C152.395 99.5831 140.854 92.6621 136.452 89.2725C131.978 85.8269 126.981 83.8749 121.916 83.4167M115.499 70.5833C124.359 70.5833 131.541 63.4012 131.541 54.5417C131.541 45.6821 124.359 38.5 115.499 38.5" :stroke="color" stroke-width="3.5" stroke-linecap="round"/>
<path d="M20.6994 115.5C15.8914 115.5 12.0671 112.474 8.63345 108.244C1.60427 99.5831 13.1452 92.6621 17.5468 89.2725C22.0214 85.8269 27.0174 83.8749 32.0829 83.4167M35.2912 70.5833C26.4316 70.5833 19.2495 63.4012 19.2495 54.5417C19.2495 45.6821 26.4316 38.5 35.2912 38.5" :stroke="color" stroke-width="3.5" stroke-linecap="round"/>
<path d="M51.8704 96.9635C45.3139 101.018 28.1234 109.296 38.5936 119.654C43.7082 124.714 49.4045 128.333 56.5662 128.333H97.4323C104.594 128.333 110.29 124.714 115.405 119.654C125.875 109.296 108.685 101.018 102.128 96.9635C86.7535 87.4567 67.245 87.4567 51.8704 96.9635Z" :stroke="color" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M99.4577 48.125C99.4577 60.5284 89.4027 70.5834 76.9993 70.5834C64.596 70.5834 54.541 60.5284 54.541 48.125C54.541 35.7216 64.596 25.6667 76.9993 25.6667C89.4027 25.6667 99.4577 35.7216 99.4577 48.125Z" :stroke="color" stroke-width="3.5"/>
</svg>
</template>

<script>

export default {
  name: 'iconUsers',
  props: {
    size: Number,
    color: String
  }
}
</script>