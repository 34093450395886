<template>
    <div class="flex flex-col h-full bg-[#343333] text-white text-left text-2xl overflow-y-auto">
        <div>
            <div class="bg-[#575757] w-full py-3 px-6">
                <span>{{ $t('Meetix.Today').toUpperCase() }}</span>
            </div>
            <div class="flex flex-col">
                <div v-for="(item, index) in todayReservations" :key="index"
                    class="flex flex-col py-4 px-6 text-[#E7E7E7]">
                    <div class="flex items-center gap-4">
                        <span>{{ getHour(item.start) }}</span>
                        <iconArrowRight :size="20" :color="'#fff'" />
                        <span>{{ getHour(item.end) }}</span>
                    </div>
                    <span class="text-2xl font-semibold text-white tracking-widest">{{ item.motif ? item.motif : "--" }}</span>
                    <span>{{ item.user.first_name + " " + item.user.last_name }}</span>
                </div>
                <div class="py-4 px-6" v-if="todayReservations.length == 0">
                    <span>{{ $t('Meetix.NoBookingsYet') }}</span>
                </div>
            </div>
        </div>
        <!-- tomorrow agenda -->
        <div>
            <div class="bg-[#575757] w-full py-3 px-6">
                <span>{{ getTomorrowDate }}</span>
            </div>
            <div class="flex flex-col">
                <div v-for="(item, index) in tomorrowReservations" :key="index"
                    class="flex flex-col py-4 px-6 text-[#E7E7E7]">
                    <div class="flex items-center gap-4">
                        <span>{{ getHour(item.start) }}</span>
                        <iconArrowRight :size="20" :color="'#fff'" />
                        <span>{{ getHour(item.end) }}</span>
                    </div>
                    <span class="text-2xl font-semibold text-white tracking-widest">{{ item.motif ? item.motif : "--" }}</span>
                    <span>{{ item.user.first_name + " " + item.user.last_name }}</span>
                </div>
                <div class="py-4 px-6" v-if="tomorrowReservations.length == 0">
                    <span>{{ $t('Meetix.NoBookingsYet') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iconArrowRight from "@/components/icons/iconArrowRight.vue";
import moment from "moment/moment";
export default {
    name: "LbrxAgendaBar",
    components: {
        iconArrowRight
    },
    props: {
        primaryColor: {
            required: true,
            value: String
        },
        secondaryColor: {
            required: true,
            value: String
        },
        thirdColor: {
            required: true,
            value: String
        },
        todayReservations: {
            required: true,
            value: Array
        },
        tomorrowReservations: {
            required: true,
            value: Array
        }
    },
    data() {
        return {
            // 
        };
    },
    computed: {
        getTomorrowDate() {
            return (this.$t('Meetix.Tomorrow') + ", " + moment().add(1, 'days').locale(this.$i18n.locale).format("dddd Do MMMM")).toUpperCase();
        }
    },
    created() {
        // 
    },
    mounted() {
        // 
    },
    methods: {
        getHour(date) {
            return moment(date).format("HH:mm")
        }
    }
};
</script>

<style scoped></style>