<template>
    <a-drawer v-model:open="sliderOpened" class="custom-class" root-class-name="root-class-name"
        :root-style="{ color: 'blue' }" style="color: white; background-color: #3D3D3D;" placement="right"
        :closable="false" width="40%">
        <div class="flex flex-col px-2 h-full justify-between">
            <div class="flex flex-col gap-10">
                <div class="flex text-white justify-between items-center">
                    <div class="flex items-center gap-3">
                        <div class="w-6 h-6 rounded-full" :class="checkIfCurrentDateIsBetweenBG(booking.start, booking.end) ? 'bg-[#E41B13]' : booking.approved == 1 ? 'bg-[#4DAD4B]' : 'bg-[#FAB401]'"></div>
                        <span class="text-4xl font-semibold" :class="checkIfCurrentDateIsBetweenBG(booking.start, booking.end) ? 'text-[#E41B13]' : booking.approved == 1 ? 'text-[#4DAD4B]' : 'text-[#FAB401]'">{{ checkIfCurrentDateIsBetweenBG(booking.start, booking.end) ? $t('Meetix.InProgress') : booking.approved == 1 ? $t('Meetix.Confirmed').toUpperCase() : $t('Meetix.OnHoldBook').toUpperCase()
                            }}</span>
                    </div>
                    <div class="m-2" @click="toggleSlider">
                        <iconCloseLight :size="40" :color="'#fff'" />
                    </div>
                </div>
                <div class="flex flex-col gap-9 text-3xl items-start pb-12">
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Organizer').toUpperCase() }}</span>
                        <span>{{ booking.user.first_name + " " + booking.user.last_name }}</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.MeetReason').toUpperCase() }}</span>
                        <span>{{ booking.motif ? booking.motif : "--" }}</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Room').toUpperCase() }}</span>
                        <span>{{ booking.room.name }}</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.DateTime').toUpperCase() }}</span>
                        <span>{{ displayDateTime }}</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Duration').toUpperCase() }}</span>
                        <span>{{ displayDuration }}</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.ParticipantNumber').toUpperCase() }}</span>
                        <span>{{ booking.participants.length + " " + $t('Meetix.Participants') }}</span>
                    </div>
                    <div class="flex flex-col" v-if="booking.periodical_settings">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Periodical').toUpperCase() }}</span>
                        <span>{{ booking.periodical_settings.type }}</span>
                        <div v-if="(booking.periodical_settings.type == 'daily' || booking.periodical_settings.type == 'weekly') && booking.periodical_settings.daysOfWeek.length != 0" class="flex">
                            <span>{{ $t('Meetix.Days')+": "+booking.periodical_settings.daysOfWeek.join(", ") }}</span>
                        </div>
                        <span>{{ $t('Meetix.EndDate')+": "+booking.periodical_settings.endDate }}</span>
                    </div>
                    <div class="flex flex-col" v-if="booking.equipments.length">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Equipments').toUpperCase() }}</span>
                        <div class="flex gap-4">
                            <span>{{ booking.equipments.map(el => el.name + " x" + el.quantity).join(", ") }}</span>
                        </div>
                    </div>
                    <div class="flex flex-col" v-if="booking.services.length">
                        <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Services').toUpperCase() }}</span>
                        <div class="flex gap-4">
                            <span>{{ booking.services.map(el => el.name + " x" + el.quantity).join(", ") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import moment from 'moment';
import iconCloseLight from "@/components/icons/iconCloseLight.vue";
export default {
    name: "LbrxSliderCalendar",
    components: {
        iconCloseLight
    },
    props: {
        booking: {
            required: true,
            value: Object
        }
    },
    data() {
        return {
            sliderOpened: false
        };
    },
    computed: {
        displayDateTime() {
            return moment(this.booking.start).locale(this.$i18n.locale).format("DD MMMM YYYY") + " " + this.$t('Meetix.At') + " " + moment(this.booking.start).format("HH:mm");
        },
        displayDuration() {
            const momentStart = moment(this.booking.start);
            const momentEnd = moment(this.booking.end);
            const duration = Math.abs(momentEnd.diff(momentStart, "minutes"));

            const minutes = duration % 60;
            const hours = Math.floor(duration / 60);
            if (hours == 0)
                return minutes + " " + this.$t('Meetix.Minutes');
            else if (minutes == 0)
                return hours + " " + this.$t('Meetix.Hours');
            else
                return hours + " " + this.$t('Meetix.Hours') + " " + minutes + " " + this.$t('Meetix.Minutes');
        },
    },
    methods: {
        getSelectedStep(step) {
            return {
                backgroundColor: step == this.currentStep ? this.primaryColor : step < this.currentStep ? "#38B02E" : "#575757"
            }
        },
        toggleSlider() {
            this.sliderOpened = !this.sliderOpened;
        },
        refresh() {
            location.reload();
        },
        checkIfCurrentDateIsBetweenBG(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const current = moment();

            return current.isBetween(start, end);
        },
    }
};
</script>

<style scoped></style>