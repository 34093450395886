<template>
    <div class="bg-[#343333] text-white flex flex-col w-screen h-screen">
        <div class="flex justify-center items-center flex-grow">
            <div>
                <div v-if="!loginVerificationShow" class="flex flex-col">
                    <div v-if="!refreshButtonStatus" class="flex flex-col">
                    <span class="text-4xl font-semibold">{{ $t('Meetix.AuthCode') }}</span>
                    <span class="text-[160px] font-semibold leading-[190px]">{{ authCode?.replace(/(\d{3})(\d{3})/,
                        "$1-$2") }}</span>
                    <span class="relative py-2 text-2xl text-gray-200">{{
                        $t('Meetix.CodeResets') }} <span>{{ countdown + " " + $t('Meetix.Seconds') }}</span></span></div>
                    <span v-else @click="forceRerender" class="mt-4 underline text-4xl">{{ $t('Meetix.RefreshCode') }}</span>
                </div>
                <div v-else class=" flex flex-col text-center text-3xl gap-12 w-[60%] xl:w-[60%] mx-auto">
                    <span>{{ $t('Meetix.ConnectFrom') }}</span>
                    <span class="text-[150px] leading-[80px] font-semibold">{{ kioskName }}</span>
                    <div class="flex gap-24 mt-3 xl:mt-8 justify-center">
                        <button @click="cancelLoginProcess"
                            class="w-28 h-28 border-2 border-white p-4 rounded-full focus:bg-white focus:text-black">
                            <IconCloseFill class="w-16 h-fit mx-auto" :size="24" :color="'#fff'" />
                        </button>
                        <button @click="authCodeConfirm"
                            class="w-28 h-28 border-2 border-white bg-white p-4 rounded-full focus:bg-white focus:text-black">
                            <iconTick class="w-16 h-fit mx-auto" :size="24" :color="'#343333'" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute bottom-0 inset-x-0 mx-auto my-4 w-max text-gray-400">
            <span>POWERED BY LIBERREX</span>
        </div>
    </div>
</template>

<script>
import { authService } from "@/_services"
import IconTick from "@/components/icons/iconTick.vue";
import IconCloseFill from "@/components/icons/iconCloseFill.vue";
import router from "@/router";
import { nextTick } from "vue";
export default {
    name: "authCode",
    inject: ['echo'],
    components: {
        IconTick,
        IconCloseFill
    },
    data() {
        return {
            authCode: "***-***",
            loginVerificationShow: false,
            dataPusher: {},
            requestLimit: 0,
            refreshButtonStatus: false,
            countdownInerval: null,
            suidSelected: false,
            suid: "",
            socketDisplay: null,
            itemDisplayLcd: "",
            modalKeyboard: {
                active: false
            },
            countdown: null,
            kioskName: "",
            showErrorCode: false
        };
    },
    created() {
        this.generateAuthCode();
        // beginning the countdown timer
        this.executeTimer();
    },
    watch: {
        authCode: function () {
            // watch it
            this.echo.channel("kiosk-auth-" + this.authCode).listen(
                ".kiosk-auth",
                function (res) {
                    console.log("listening ....");
                    this.loginVerificationShow = true;
                    this.dataPusher =  res;
                    this.kioskName = this.dataPusher.kiosk.name;
                }.bind(this)
            );
        },
    },
    beforeUnmount() {
        clearInterval(this.countdownInerval);
    },
    methods: {
        generateAuthCode() {
            authService.generateCode().then((res) => {
                this.authCode = res.code;
                this.requestLimit += 1;
                this.showErrorCode = false;
            }).catch((error) => {
                this.showErrorCode = true;
                this.requestLimit = 0;
                clearInterval(this.countdownInerval);
                console.log("error api", error);
            });
        },
        authCodeConfirm() {
            let payload = {
                kiosk_id: this.dataPusher.kiosk.id,
                auth_token: this.dataPusher.token
            }
            authService.authCodeConfirm(payload).then((res) => {
                if (res.device.config) {
                    this.setThemeTemplate(JSON.parse(res.device.config));
                } else {
                    this.setThemeTemplateDefault();
                }
                localStorage.setItem("device", JSON.stringify(res.device));
                router.push("/home");
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        cancelLoginProcess() {
            this.authCode = "***-***";
            this.loginVerificationShow = false;
            this.dataPusher = {};
            this.forceRerender();
        },
        setThemeTemplate(theme) {
            const themeTemplate = {
                primaryColor: theme.primaryColor ? theme.primaryColor : "#660066",
                secondaryColor: theme.secondaryColor ? theme.secondaryColor : "#fbb900",
                thirdColor: theme.thirdColor ? theme.thirdColor : "#e41b13"
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
        setThemeTemplateDefault() {
            const themeTemplate = {
                primaryColor: "#660066",
                secondaryColor: "#fbb900",
                thirdColor: "#e41b13"
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
        executeTimer() {
            // let countdownNumberEl = document.getElementById("countdown-number");
            this.countdown = 60;
            // countdownNumberEl.textContent = countdown;

            this.countdownInerval = setInterval(
                function () {
                    if (this.countdown == 1) {
                        if (this.requestLimit >= 2) {
                            clearInterval(this.countdownInerval);
                            this.refreshButtonStatus = true;
                            this.authCode = "***-***";
                        } else {
                            this.generateAuthCode();
                        }
                    }
                    this.$nextTick(() => {
                        this.countdown = --this.countdown <= 0 ? 60 : this.countdown;
                    })

                    // countdownNumberEl.textContent = this.countdown;
                }.bind(this),
                1000
            );
        },
        forceRerender() {
            this.requestLimit = 0;
            this.refreshButtonStatus = false;
            setTimeout(() => {
                clearInterval(this.countdownInerval);
                this.generateAuthCode();
                this.executeTimer();
            }, 500);
        }
    }
};
</script>

<style scoped>
/* timer part */


.button-refresh {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 60px;
    height: 100px;
    text-align: center;
}
</style>