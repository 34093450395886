<template>
  <div class="flex justify-between py-4 px-7 items-center gradient-header">
    <div class="flex items-center gap-6">
      <div @click="toggleSlider" class="bg-white p-2 rounded-xl">
        <iconMenu class="w-8 h-fit mx-auto" :size="24" :color="primaryColor"/>
      </div>
      <div class="flex flex-col text-xl leading-7 text-white text-left px-4">
        <span v-if="device.organizaton !== null" class="">{{ device.organization.name }}</span>
        <span class="font-semibold text-3xl tracking-widest">{{ displayRoomName() }}</span>
      </div>
    </div>

    <div class="flex gap-5">
      <div @click="toggleSearchPopup" class="bg-white p-2 rounded-xl">
        <IconSearchV2 class="w-8 h-fit mx-auto" :size="24" :color="'#e41b13'"/>
      </div>
      <div v-if="route.currentRoute.name == 'Calendar'" @click="goRoute('/home')" class="bg-white p-2 rounded-xl">
        <iconHome class="w-8 h-fit mx-auto" :size="24" :color="'#e41b13'"/>
      </div>
      <div v-else @click="goRoute('/calendar')" class="bg-white p-2 rounded-xl">
        <iconCalendar class="w-8 h-fit mx-auto" :size="24" :color="'#e41b13'"/>
      </div>
    </div>
  </div>
</template>

<script>
import iconMenu from "@/components/icons/iconMenu.vue";
import iconCalendar from "@/components/icons/iconCalendar.vue";
import iconHome from "@/components/icons/iconHome.vue"
import router from "@/router";
import {useRouter} from 'vue-router'
import IconSearchV2 from "./icons/iconSearchV2.vue";

export default {
  name: "LbrxHeader",
  components: {
    iconMenu,
    iconCalendar,
    iconHome,
    IconSearchV2
  },
  props: {
    primaryColor: {
      required: true,
      value: String
    },
    secondaryColor: {
      required: true,
      value: String
    },
    thirdColor: {
      required: true,
      value: String
    },
    room: {
      required: true,
      value: Object
    },
    device: {
      required: true,
      value: Object
    }
  },
  data() {
    return {
      route: null
    };
  },
  created() {
    this.route = useRouter();
    window.addEventListener('room-changed', (event) => {
      this.displayRoomName();
    });
  },
  methods: {
    displayRoomName() {
      return localStorage.getItem('selectedRoom') ? JSON.parse(localStorage.getItem('selectedRoom')).name : this.room.name;
    },
    toggleSlider() {
      this.$emit("toggleSlider");
    },
    goRoute(route) {
      router.push(route);
    },
    toggleSearchPopup() {
      this.$emit("toggleSearchPopup");
    },
  }
};
</script>

<style scoped>
.gradient-header {
  background-image: linear-gradient(90deg, v-bind(primaryColor) 0%, v-bind(thirdColor) 48.5%, v-bind(secondaryColor) 100%);
}
</style>