<template>
    <div class="flex flex-col h-full justify-around w-full text-white overflow-y-auto py-8">
        <div class="flex flex-col gap-5">
            <div class="flex justify-center text-left gap-10 mx-12">
                <div class="flex flex-col w-full h-[50vh] overflow-auto no-scrollbar">
                    <span class="text-xl my-5">{{ $t('Meetix.Equipments') }}</span>
                    <div class="grid grid-cols-3 gap-4">
                        <div v-for="(item, index) in equipments" :key="index" class="bg-[#5e5e5e] flex flex-col gap-4"
                            :class="findEquipment(item) != -1 ? 'bg-[#fbb900]' : ''">
                            <div @click="selectEquipment(item)" class="flex flex-col text-center gap-2 pt-4">
                                <img :src="getImagePathEquipment(item.icon)" alt="" class="w-8 mx-auto">
                                <div class="h-[32px] flex justify-center px-2">
                                    <span class="leading-4 line-clamp-2 pb-[1px]">{{ item.label }}</span>
                                </div>
                            </div>
                            <div class="flex justify-center bg-[#272727]"
                                :class="findEquipment(item) == -1 ? 'opacity-85' : ''">
                                <button :disabled="findEquipment(item) == -1" @click="toggleQtyEquip('substract', item)"
                                    class="bg-[#272727] h-14 w-14 flex justify-center items-center">
                                    <iconMinus :size="30" :color="'#fff'" />
                                </button>
                                <div class="bg-[#272727] h-14 flex items-center px-4 max-md:w-32 ">
                                    <p class="text-nowrap min-w-2 overflow-hidden">{{ item.qty }}</p>
                                </div>
                                <button :disabled="findEquipment(item) == -1" @click="toggleQtyEquip('add', item)"
                                    class="bg-[#272727] h-14 w-14 flex justify-center items-center">
                                    <iconPlus :size="30" :color="'#fff'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-full h-[50vh] overflow-auto no-scrollbar">
                    <span class="text-xl my-5">{{ $t('Meetix.Services') }}</span>
                    <div class="grid grid-cols-3 gap-4">
                        <div v-for="(item, index) in services" :key="index" class="bg-[#5e5e5e] flex flex-col gap-4"
                            :class="findService(item) != -1 ? 'bg-[#fbb900]' : ''">
                            <div @click="selectService(item)" class="flex flex-col text-center gap-2 pt-4">
                                <img :src="getImagePathService(item.icon)" alt="" class="w-8 mx-auto">
                                <div class="h-[32px] flex justify-center px-2">
                                    <span class="leading-4 line-clamp-2 pb-[1px]">{{ item.label }}</span>
                                </div>
                            </div>
                            <div class="flex justify-center bg-[#272727]"
                                :class="findService(item) == -1 ? 'opacity-85' : ''">
                                <button :disabled="findService(item) == -1"
                                    @click="toggleQtyServices('substract', item)"
                                    class="bg-[#272727] h-14 w-14 flex justify-center items-center">
                                    <iconMinus :size="30" :color="'#fff'" />
                                </button>
                                <div class="bg-[#272727] h-14 flex items-center px-4 max-md:w-32 ">
                                    <p class="text-nowrap min-w-2 overflow-hidden">{{ item.qty }}</p>
                                </div>
                                <button :disabled="findService(item) == -1" @click="toggleQtyServices('add', item)"
                                    class="bg-[#272727] h-14 w-14 flex justify-center items-center">
                                    <iconPlus :size="30" :color="'#fff'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <button @click="nextStep"
                class="flex items-center border-2 border-white px-24 py-1 text-3xl font-semibold gap-10">{{
                    $t('Meetix.Next').toUpperCase() }}
                <iconArrowRight :size="50" :color="'#fff'" />
            </button>

        </div>
    </div>
</template>

<script>
import iconArrowRight from "@/components/icons/iconArrowRight.vue";
import iconPlus from "@/components/icons/iconPlus.vue";
import iconMinus from "@/components/icons/iconMinus.vue";
import VueMultiselect from 'vue-multiselect'
import { serviceService, equipmentService } from "@/_services";
import IconCloseFill from "./icons/iconCloseFill.vue";
import { notification } from 'ant-design-vue';
export default {
    name: "LbrxEquipmentSelection",
    components: {
        iconArrowRight,
        iconPlus,
        iconMinus,
        // VueMultiselect,
        // IconCloseFill
    },
    props: {
        services: {
            required: false,
            value: Array
        },
        equipments: {
            required: false,
            value: Array
        }
    },
    data() {
        return {
            employeeId: "",
            meetReason: "",
            duration: 30,
            durationMeet: 0,
            participantNumber: 1,
            debounceTimer: null,
            fetching: false,
            selectedEquip: [],
            selectedServices: []
        };
    },
    created() {
        this.durationMeet = this.duration;
    },
    computed: {
        displayDurtaion() {
            const minutes = this.durationMeet % 60;
            const hours = Math.floor(this.durationMeet / 60);
            if (hours != 0)
                return hours + " " + this.$t('Meetix.Hours') + " " + minutes + " " + this.$t('Meetix.Minutes');
            else
                return minutes + " " + this.$t('Meetix.Minutes');
        }
    },
    methods: {
        getImagePathEquipment(imageName) {
            try {
                // Try to require the image dynamically
                return require(`../assets/icons/${imageName}.svg`);
            } catch (error) {
                console.warn(`Icon not found: ${imageName}`);
                // Return a fallback image if the original image is not found
                return require('../assets/icons/equipment.svg');
            }
        },
        getImagePathService(imageName) {
            try {
                // Try to require the image dynamically
                return require(`../assets/icons/${imageName}.svg`);
            } catch (error) {
                console.warn(`Icon not found: ${imageName}`);
                // Return a fallback image if the original image is not found
                return require('../assets/icons/service.svg');
            }
        },
        nextStep() {
            this.$emit("nextStep", "infoValidation",
                {
                    selectedEquip: this.selectedEquip,
                    selectedServices: this.selectedServices
                });
            this.$emit("resetQty");
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        toggleQtyEquip(type, item) {
            if (type == "add") {
                if (item.qty < 30)
                    item.qty += 1;
            } else {
                if (item.qty > 1)
                    item.qty -= 1;
                else {
                    const index = this.selectedEquip.findIndex((el) => el.value == item.value);
                    item.qty = 0;
                    this.selectedEquip.splice(index, 1)
                }
            }
        },
        toggleQtyServices(type, item) {
            if (type == "add") {
                if (item.qty < 30)
                    item.qty += 1;
            } else {
                if (item.qty > 1)
                    item.qty -= 1;
                else {
                    const index = this.selectedServices.findIndex((el) => el.value == item.value);
                    item.qty = 0;
                    this.selectedServices.splice(index, 1)
                }
            }
        },
        findService(item) {
            return this.selectedServices.findIndex((el) => el.value === item.value);
        },
        selectService(item) {
            const index = this.findService(item);
            if (index == -1) {
                item.qty = 1;
                this.selectedServices.push(item);
            } else {
                item.qty = 0;
                this.selectedServices.splice(index, 1);
            }
        },
        findEquipment(item) {
            return this.selectedEquip.findIndex((el) => el.value === item.value);
        },
        selectEquipment(item) {
            const index = this.findEquipment(item);
            if (index == -1) {
                item.qty = 1;
                this.selectedEquip.push(item);
            } else {
                item.qty = 0;
                this.selectedEquip.splice(index, 1);
            }
        }

    }
};
</script>
<style>
.custom__tag {
    background-color: darkgrey;
    color: #272727;
    padding: 3px 5px;
    margin: 1px 5px;
    border-radius: 5px;
    display: flex;
}

.multiselect__tags .multiselect__spinner {
    color: aqua;
    background-color: aquamarine;
}

.multiselect__tags {
    background-color: #272727 !important;
    width: 100%;
    /* height: 4rem; */
}

.multiselect__tags input {
    background-color: #272727 !important;
    height: 4rem;
    outline: none;
    display: block;
    font-size: 15px;
    padding: 0 10px;
}

.multiselect__tags .multiselect__placeholder {
    line-height: 4rem;
    padding: 0 10px;
}

.multiselect__tags-wrap {
    background-color: #272727 !important;
    background: #272727;
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
}

.multiselect__select {
    background-color: #272727 !important;
    background: #272727;
}

.multiselect__content-wrapper {
    background-color: #272727 !important;
    background: #272727;
    padding: 0 10px;
}

.multiselect__content-wrapper .multiselect__content .multiselect__element {
    padding: 5px 0;
}
</style>
<style scoped>
input {
    font-size: 30px;
}

p {
    font-size: 30px;
}
</style>