<template>
    <svg :width="size" :height="size" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M77 141.167C41.5618 141.167 12.8336 112.438 12.8336 77C12.8336 41.5617 41.562 12.8333 77.0003 12.8333C105.732 12.8333 129.782 31.7169 137.958 57.75H121.917" :stroke="color" stroke-width="6.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M77 51.3333V77L89.8333 89.8333" :stroke="color" stroke-width="6.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M140.878 83.4167C141.069 81.3047 141.167 79.1643 141.167 77M96.25 141.167C98.4419 140.445 100.583 139.604 102.667 138.65M133.406 109.083C134.644 106.699 135.748 104.228 136.707 101.68M116.735 129.804C118.945 127.975 121.038 125.996 123 123.883" :stroke="color" stroke-width="6.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>

export default {
  name: 'iconClock',
  props: {
    size: Number,
    color: String
  }
}
</script>