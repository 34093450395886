<template>
    <svg :width="size" :height="size" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27 3V6M9 3V6" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.575 33C10.5292 33 7.50636 33 5.62818 30.9695C3.75 28.9391 3.75 25.6711 3.75 19.1351V18.3649C3.75 11.8289 3.75 8.56092 5.62818 6.53046C7.50636 4.5 10.5292 4.5 16.575 4.5H19.425C25.4708 4.5 28.4936 4.5 30.3718 6.53046C32.2147 8.52279 32.2493 11.7066 32.25 18" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 12H31.5" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.5 27H31.5M25.5 21L25.5 33" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconAddBook',
  props: {
    size: Number,
    color: String
  }
}
</script>