import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import Echo from "laravel-echo";
import {ApiConfigs, authHeader} from "@/_helpers";

import {createI18n} from "vue-i18n";
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'fr')
}

const i18n = createI18n({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: localStorage.getItem('Language'),
    fallbackLocale: "fr",
    messages: {fr, en, ar},
});

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// const themeTemplate = {
//     primaryColor: "#660066",
//     secondaryColor: "#fbb900",
//     thirdColor: "#e41b13"
// }

// localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))

import Pusher from 'pusher-js';
window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: (process.env.VUE_APP_EDIT_HOST === "true" && localStorage.getItem('host') != null) ? localStorage.getItem('ws') : process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: true,
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    enabledTransports: ["ws", "wss"],
    auth: {
        headers: {...authHeader()},
    },
});

const urlPhoto = process.env.VUE_APP_PHOTO_URL_CHECK;
const longPressTimer = process.env.VUE_APP_LONG_PRESS_TIMER;

const app = createApp(App).use(store).use(router).use(Antd).use(i18n)
app.component('VueDatePicker', VueDatePicker);
app.provide('echo', echo)
app.provide('urlPhoto', urlPhoto)
app.provide('longPressTimer', longPressTimer)
app.mount('#app')
