import { authHeader, ApiConfigs } from "../_helpers";
// import store from '@/store';

export const userService = {
    getById,
    getAll
};

function getById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAll(param: any = "") {
    const requestOptions = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.user.getAll + param}`, requestOptions)
        .then(
            handleResponse
        );
}


function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("device");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}
