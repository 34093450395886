import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Meetix from '../views/meetix/index.vue';
import Home from '../views/home/index.vue';
import Booking from '../views/booking/index.vue';
import Calendar from '../views/calendar/index.vue';
import authCode from '../views/auth/authCode.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/authentication',
    name: 'Authentication',
    component: authCode,
  },
  {
    path: '/',
    name: 'Meetix',
    component: Meetix,
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/booking',
        name: 'Booking',
        component: Booking
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  const deviceLoggedIn = localStorage.getItem('device') || null;
  if (to.name != "Authentication" && deviceLoggedIn === null) {
    localStorage.removeItem("themeTemplate")
    return next('/authentication');
  }

  if (to.name == "Authentication" && deviceLoggedIn !== null) {
    return next('/home');
  }
  if (to.name == "Meetix" && deviceLoggedIn !== null) {
    return next('/home');
  }

  next();
})

export default router
