import {authHeader, ApiConfigs} from "../_helpers";
// import store from '@/store';

export const authService = {
    logout,
    generateCode,
    authCodeConfirm,
    handleResponseLougout
};

function generateCode(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };
    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.kiosk.generate}`,
        requestOptions
    ).then(handleResponse);
}

function authCodeConfirm(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload)
    };
    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.kiosk.authCodeConfirm}`,
        requestOptions
    ).then(handleResponse);
}

function logout() {
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.logout}`, requestOptions).then(
        handleResponseLougout
    );
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseLougout(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}
