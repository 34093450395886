<template>
    <div class="flex flex-col h-full w-full bg-[#343333] text-white text-xl overflow-y-auto">
        <div @click="goBack(item)" v-for="(item, index) in steps" :key="index" class="flex flex-col p-4 bg-[#575757] mb-[2px]" :style="getSelectedStep(item)">
            <span>{{ $t('Meetix.Step') }}</span>
            <span class="text-7xl font-semibold">{{ item }}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "LbrxStepSidebar",
    props: {
        primaryColor: {
            required: true,
            value: String
        },
        currentStep: {
            required: true,
            value: Number
        },
        steps: {
            required: true,
            value: Array
        }
    },
    data() {
        return {
            // 
        };
    },
    methods: {
        getSelectedStep(step) {
            return {
                    backgroundColor: step == this.currentStep ? this.primaryColor : step < this.currentStep ? "#38B02E" : "#575757"
                }
        },
        goBack(step) {
            if(step < this.currentStep && this.currentStep != 5) {
                this.$emit("goBack", step)
            }
        }
    }
};
</script>

<style scoped></style>