<template>
    <div class="w-screen h-screen flex flex-col">
        <a-config-provider :theme="{
            token: {
                fontFamily: 'Inria Sans',
                colorPrimary: '#fbb900',
            },
        }"></a-config-provider>
        <LbrxSlider ref="sliderRef" :route="route" @toggleLogoutPopup="toggleLogoutPopup" @toggleAssistPopup="toggleAssistPopup"
        @toggleAboutPopup="toggleAboutPopup" />
        <LbrxHeader :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor"
            :thirdColor="themeTemplate.thirdColor" @toggleSlider="toggleSlider" :room="room" :device="device"
            @toggleLoader="toggleLoader" @toggleSearchPopup="toggleSearchPopup" />
        <div class="flex flex-grow bg-[#ebeef0] overflow-hidden">
            <div class="w-full flex">
                <router-view></router-view>
            </div>
        </div>
        <div class="absolute bottom-0 right-0 my-4 mx-6 z-50 text-gray-400">
            <span>POWERED BY LIBERREX</span>
        </div>
        <LbrxLogoutPopup :active="modal.active" @toggleModal="toggleLogoutPopup" @logout="logout" />
        <LbrxAssistPopup :active="modalAssist.active" @toggleModal="toggleAssistPopup" @logout="logout" />
        <LbrxAboutPopup :active="modalAbout.active" @toggleModal="toggleAboutPopup" @logout="logout" />
        <LbrxSearchRoomPopup :active="modalSearch.active" @toggleModal="toggleSearchPopup" :duration="30" :color="'#FBB601'" />
        <LbrxLoader v-if="loading" :color="'#FBB601'" />
    </div>
</template>

<script>
import { authService, roomService } from '@/_services';
import LbrxHeader from '@/components/LbrxHeader.vue';
import LbrxSlider from '@/components/LbrxSlider.vue';
import LbrxLoader from "@/components/LbrxLoader"
import LbrxLogoutPopup from "@/components/LbrxLogoutPopup"
import LbrxAssistPopup from "@/components/LbrxAssistPopup"
import LbrxAboutPopup from "@/components/LbrxAboutPopup"
import LbrxSearchRoomPopup from "@/components/LbrxSearchRoomPopup"
import { useRouter } from 'vue-router'
export default {
    name: "MeetixIndex",
    inject: ['echo'],
    components: {
        LbrxHeader,
        LbrxSlider,
        LbrxLoader,
        LbrxLogoutPopup,
        LbrxSearchRoomPopup,
        LbrxAssistPopup,
        LbrxAboutPopup
    },
    data() {
        return {
            themeTemplate: {},
            device: {},
            deviceConfig: {},
            room: {},
            loading: false,
            route: null,
            modal: {
                active: false
            },
            modalSearch: {
                active: false
            },
            modalAssist: {
                active: false
            },
            modalAbout: {
                active: false
            }
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.device = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.deviceConfig = JSON.parse(this.device.config);
            }
        }
        if (localStorage.getItem('themeTemplate')) {
            this.themeTemplate = JSON.parse(localStorage.getItem('themeTemplate'));
        }
        this.connectPusherRefresh();
        if (!localStorage.getItem('room')) {
            this.getRoomById();
        } else {
            this.room = JSON.parse(localStorage.getItem('room'))
        }
        this.route = useRouter();
    },
    mounted() {
        // 
    },
    methods: {
        connectPusherRefresh() {
            this.echo.channel("kiosk-" + this.device.id).listen(
                ".kiosk-refresh",
                function (res) {
                    console.log("listening to refresh_channel");
                    localStorage.setItem("device", JSON.stringify(res.device));
                    this.setThemeTemplate(JSON.parse(res.device.config));
                    localStorage.removeItem("room");
                    location.reload();
                }.bind(this)
            );

            this.echo.channel("kiosk-" + this.device.id).listen(
                ".kiosk-logout",
                function (res) {
                    console.log("listening to logout_channel");
                    this.logout();
                }.bind(this)
            );
        },
        setThemeTemplate(theme) {
            const themeTemplate = {
                primaryColor: theme.primaryColor ? theme.primaryColor : "#660066",
                secondaryColor: theme.secondaryColor ? theme.secondaryColor : "#fbb900",
                thirdColor: theme.thirdColor ? theme.thirdColor : "#e41b13"
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
        toggleSlider() {
            this.$refs.sliderRef.toggleSlider();
        },
        getRoomById() {
            // this.loading = true;
            roomService.getById(this.deviceConfig.room_id).then((res) => {
                this.room = res.data;
                localStorage.setItem("room", JSON.stringify(res.data));
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                // this.loading = false;
            });
        },
        toggleLoader() {
            this.loading = true;
            window.location.reload();
            setTimeout(() => {
                this.loading = false;
            }, 3000);
        },
        toggleLogoutPopup() {
            this.modal.active = !this.modal.active
        },
        toggleAssistPopup() {
            this.modalAssist.active = !this.modalAssist.active
        },
        toggleAboutPopup() {
            this.modalAbout.active = !this.modalAbout.active
        },
        toggleSearchPopup() {
            this.modalSearch.active = !this.modalSearch.active
        },
        logout() {
            authService.logout().then(() => {
                localStorage.removeItem("room");
                localStorage.removeItem("device");
                localStorage.removeItem("themeTemplate");
                this.echo.leaveChannel("kiosk-" + this.device.id);
                location.reload();
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
            })
        }
    }
};
</script>

<style scoped></style>