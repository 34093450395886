<template>
    <div class="fixed w-screen h-screen flex justify-center items-center" style="z-index: 9999999999;">

        <div class="mb-44 z-50" >
            <svg width="88" height="88" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :stroke="color" >
                <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)" stroke-width="2">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18"
                                dur="1s" repeatCount="indefinite" />
                        </path>
                    </g>
                </g>
            </svg>
        </div>
        <div class="fixed h-screen w-screen top-0 left-0 backdrop-blur-sm bg-[#1e1e1e80]"></div>
    </div>
</template>

<script>
export default {
    name: "LbrxLoader",
    components: {
    },
    props: {
        color: {
            required: false,
            value: String,
            default: "#ffffalse"
        }
    },
    data() {
        return {
        };
    },
};
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: aqua;

}
</style>