<template>
    <svg :width="size" :height="size" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33C26.2843 33 33 26.2843 33 18Z" :stroke="color" stroke-width="2.5"/>
<path d="M18.3633 25.5V18C18.3633 17.2929 18.3633 16.9393 18.1436 16.7197C17.9239 16.5 17.5704 16.5 16.8633 16.5" :stroke="color" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.988 11.1819H18.0015" :stroke="color" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconInfo',
  props: {
    size: Number,
    color: String,
    colorFill: String
  }
}
</script>