<template>
    <div v-if="active" class="fixed w-screen h-screen flex justify-center items-center z-50">

        <div class="bg-[#3D3D3D] relative z-30 text-white px-6 py-8 w-[60vw]">
            <span class="absolute right-6 top-2 scale-[240%] " @click="toggleModal">&times;</span>
            <div class="flex flex-col gap-4 text-left">
                <div class="flex flex-col">
                    <span class="text-4xl">{{ $t('Meetix.About') }}</span>
                </div>
                <div class="flex flex-col mt-8 mb-3">
                    <span class="text-xl">{{ $t('Meetix.Description') }}</span>
                </div>
            </div>
        </div>
        <div @click="toggleModal" class="fixed h-screen w-screen top-0 left-0 backdrop-blur-sm bg-[#1e1e1e80]"></div>
    </div>
</template>

<script>
export default {
    name: "LbrxAboutPopup",
    components: {
    },
    props: {
        active: {
            required: true,
            value: Boolean
        }
    },
    data() {
        return {
            matricule: "",
            primaryColor: "#008FCA",
            secondaryColor: "#96C9F4"
        };
    },
    methods: {
        toggleModal() {
            this.matricule = "";
            this.$emit("toggleModal");
        },
        logout() {
            if (this.matricule.length != 0) {
                this.$emit("logout");
            }
        }
    }
};
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: aqua;

}
.gradient-header {
    background-image: linear-gradient(90deg, v-bind(primaryColor) 0%, v-bind(secondaryColor) 100%);
}
</style>