<template>
    <div class="flex flex-col justify-around w-full text-white overflow-y-auto py-3">
        <span class="text-3xl font-semibold mb-3">{{ $t('Meetix.PleaseChooseDate') }}</span>
        <div class="flex gap-6 px-12 mb-5">
            <div class="bg-[#575757] flex w-[45%] h-[60vh] overflow-y-auto no-scrollbar">
                <Datepicker inline="true" :monday-first="true" maximum-view="day" :disabled-dates="disabledDates"
                    :preventDisableDateSelection="true" :value="date" @selected="handleSelectDate" :language="$i18n.locale">
                </Datepicker>
            </div>
            <div class="bg-[#575757] p-2 flex flex-wrap flex-1 content-start relative">
                <div @click="selectSlot(item.slot, item.active)" v-for="(item, index) in slots" :key="index"
                    class="text-2xl w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 aspect-w-1 aspect-h-1"
                    :class="item.active == 0 ? 'bg-[#A7A7A7] text-[#575757]' : selectedSlot == item.slot ? 'bg-[#FBB601] text-[#fff]' : 'bg-white text-[#575757]'">
                    <div class="border-y-[6px] border-x-8 border-[#575757] py-2">
                        <span>{{ item.slot }}</span>
                    </div>
                </div>
                <div class="text-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span v-if="slots.length == 0">{{ $t('Meetix.NoSlots') }}</span>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <button @click="nextStep"
                class="flex items-center border-2 border-white px-24 py-1  text-3xl font-semibold gap-10">{{
                    $t('Meetix.Next').toUpperCase() }}
                <iconArrowRight :size="50" :color="'#fff'" />
            </button>
        </div>
    </div>
</template>

<script>
import iconArrowRight from "@/components/icons/iconArrowRight.vue";
import Datepicker from 'vuejs3-datepicker';
export default {
    name: "LbrxDateSelection",
    components: {
        iconArrowRight,
        Datepicker
    },
    props: {
        secondaryColor: {
            required: true,
            value: String
        },
        date: {
            required: true,
            value: String
        },
        slots: {
            required: true,
            value: Array
        },
        selectedSlot: {
            required: true,
            value: String
        }
    },
    data() {
        return {
            disabledDates: {
                days: [0, 6],
                to: new Date(Date.now() - 86400000), // Disable all dates up to specific date
                preventDisableDateSelection: true
            }
        };
    },
    methods: {
        nextStep() {
            this.$emit("nextStep", "infoSelection");
        },
        handleSelectDate(date) {
            this.$emit("handleSelectDate", date);
        },
        selectSlot(slot, status) {
            if(status == 1) {
                this.$emit("selectSlot", slot);
            }
        }
    }
};
</script>

<style>
.vuejs3-datepicker__calendar {
    width: 100%;
    height: 100%;
    background: none;
    box-shadow: none;
    color: white;
}

.vuejs3-datepicker__calendar-topbar {
    display: none;
}

.vuejs3-datepicker * {
    text-align: justify;
    align-content: center;
    font-size: 24px;
    padding: 0 10px;
}

.vuejs3-datepicker__calendar .cell {
    border: none;
}

.vuejs3-datepicker__calendar .day {
    font-size: 18px;
    height: 72px;
    border-bottom: 0.5px solid rgba(251, 182, 1, .3);
}

.vuejs3-datepicker__calendar .disabled {
    color: #989797;
}

.vuejs3-datepicker__calendar header span {
    font-size: 35px;
    color: v-bind(secondaryColor);
}

.vuejs3-datepicker__calendar header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.vuejs3-datepicker__calendar .cell.day-header {
    color: v-bind(secondaryColor);
}

.vuejs3-datepicker__calendar header .prev:after {
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    border-radius: 0;
    height: 22px;
    width: 22px;
    margin-left: 4px;
}

.vuejs3-datepicker__calendar header .prev {
    /* padding: 10px 0; */
    width: 54px;
    height: 54px;
    border-radius: 0;
    background-color: v-bind(secondaryColor);
}

.vuejs3-datepicker__calendar header .next:after {
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    border-radius: 0;
    height: 22px;
    width: 22px;
    position: absolute;
    left: 22px;
}

.vuejs3-datepicker__calendar header .next {
    padding: 10px 0;
    width: 54px;
    height: 54px;
    border-radius: 0;
    background-color: v-bind(secondaryColor);
}

.vuejs3-datepicker__calendar-actionarea {
    padding: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vuejs3-datepicker__calendar .cell.selected {
    background: v-bind(secondaryColor);
}
</style>