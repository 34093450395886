<template>
    <div class="w-full h-full flex flex-col relative text-white">

        <div class="w-full py-2 px-5 text-left text-xl bg-[#575757]">
            <span>{{ $t('Meetix.BookingRequest').toUpperCase() }}</span>
        </div>
        <div class="bg-[#3D3D3D] flex-1 flex">
            <div class="min-w-28">
                <LbrxStepSidebar :primaryColor="themeTemplate.primaryColor" :currentStep="currentStep" :steps="steps"
                    @goBack="goBack" />
            </div>
            <div class="flex-1">
                <LbrxDateSelection v-if="currentStep == 1" @nextStep="nextStep"
                    :secondaryColor="themeTemplate.secondaryColor" :date="date" @handleSelectDate="handleSelectDate"
                    :slots="slots" :selectedSlot="selectedSlot" @selectSlot="selectSlot" />
                <LbrxInfoSelection ref="infoSelection" v-if="currentStep == 2" @nextStep="nextStep" :start="slots[0]"
                    :end="slots[slots.length - 1]" :selectedRoom="selectedRoom" :date="date" />
                <LbrxEquipmentSelection v-if="currentStep == 3" @nextStep="nextStep" :services="services"
                    :equipments="equipments" @resetQty="resetQty" />
                <LbrxInfoValidation v-if="currentStep == 4" @nextStep="nextStep" :info="info" :employee="employee"
                    :date="date" :selectedSlot="selectedSlot" :room="room" />
                <div v-if="currentStep == 5" class="flex flex-col items-center justify-center h-full gap-6">
                    <iconValidation :size="160" :color="'#4DAD4B'" class="container" />
                    <div class="container-text flex flex-col gap-5">
                        <span class="text-5xl font-semibold text-[#4DAD4B]">{{ $t('Meetix.RequestSent').toUpperCase()
                            }}</span>
                        <div class="flex flex-col tracking-wider text-xl">
                            <span>{{ $t('Meetix.RequestSentBook') }}</span>
                            <span>{{ $t('Meetix.RequestEmail') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <LbrxLoader v-if="loading" :color="'#FBB601'" />
</template>

<script>
import LbrxDateSelection from "@/components/LbrxDateSelection"
import LbrxInfoSelection from "@/components/LbrxInfoSelection"
import LbrxEquipmentSelection from "@/components/LbrxEquipmentSelection"
import LbrxInfoValidation from "@/components/LbrxInfoValidation"
import LbrxStepSidebar from "@/components/LbrxStepSidebar"
import LbrxLoader from "@/components/LbrxLoader"
import iconValidation from "@/components/icons/iconValidation.vue"
import { reservationService, roomService, serviceService, userService, equipmentService } from "@/_services"
import { notification } from 'ant-design-vue';
import moment from 'moment';
import router from "@/router";
export default {
    name: "bookingIndex",
    components: {
        LbrxDateSelection,
        LbrxStepSidebar,
        LbrxInfoSelection,
        LbrxInfoValidation,
        iconValidation,
        LbrxLoader,
        LbrxEquipmentSelection
    },
    data() {
        return {
            themeTemplate: {},
            device: {},
            deviceConfig: {},
            room: {},
            currentStep: 1,
            steps: ["1", "2", "3", "4"],
            showDateSelection: true,
            showInfoSelection: false,
            showValidation: false,
            showStatus: false,
            loading: false,
            slots: [],
            services: [],
            equipments: [],
            date: moment().format("YYYY-MM-DD"),
            duration: 30,
            selectedSlot: null,
            selectedRoom: null,
            info: {
                employeeId: "",
                meetReason: "",
                durationMeet: 0,
                selectedPart: [],
                selectedEquip: [],
                selectedServices: []
            },
            employee: {}
        };
    },
    created() {
        this.getSelectedRoomConfig();
        if (localStorage.getItem('room')) {
            this.room = JSON.parse(localStorage.getItem('room'));
        }
        if (localStorage.getItem('themeTemplate')) {
            this.themeTemplate = JSON.parse(localStorage.getItem('themeTemplate'));
        }
        if (localStorage.getItem('device')) {
            this.device = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.deviceConfig = JSON.parse(this.device.config);
            }
        }
        this.getSlots(this.date);
        this.getServices();
        this.getEquipements();
    },
    mounted() {
        // 
    },
    methods: {
        getSelectedRoomConfig() {
            this.selectedRoom = localStorage.getItem('selectedRoom') ? JSON.parse(localStorage.getItem('selectedRoom')) : null;
            if (this.selectedRoom) {
                this.date = moment(this.selectedRoom.selectedTime).format("YYYY-MM-DD");
                this.selectedSlot = moment(this.selectedRoom.selectedTime).format("HH:mm");
            } else {
                this.date = moment().format("YYYY-MM-DD");
            }
        },
        nextStep(step, info) {
            switch (step) {
                case "infoSelection":
                    if (this.selectedSlot) {
                        this.showDateSelection = false;
                        this.showInfoSelection = true;
                        this.currentStep += 1;
                    }
                    break;
                case "equipmentSelection":
                    this.info.employeeId = info.employeeId;
                    this.info.meetReason = info.meetReason;
                    this.info.durationMeet = info.durationMeet;
                    this.info.selectedPart = info.selectedPart;
                    this.info.allowPeriodical = info.allowPeriodical;
                    if (info.allowPeriodical) {
                        this.info.type = info.type;
                        this.info.periodicity = info.periodicity;
                        this.info.endDate = info.endDate;
                        this.info.daysOfWeek = info.daysOfWeek;
                        this.info.time = this.selectedSlot;
                        this.info.duration = moment.utc(this.info.durationMeet * 60 * 1000).format('HH:mm');
                        this.getRoomAvailabilityPeriod();
                    } else {
                        this.getUserById();
                    }
                    this.showInfoSelection = false;
                    this.showEquipmentSelection = true;
                    break;

                case "infoValidation":
                    this.info.selectedEquip = info.selectedEquip;
                    this.info.selectedServices = info.selectedServices;
                    this.currentStep += 1;
                    break;
                case "status":
                    this.createReservation();
                    break;
                default:
                    console.warn(`${step}. does not exist`);
            }
        },
        getSlots(date = moment().format("YYYY-MM-DD")) {
            this.loading = true;
            let payload = {
                room_id: this.selectedRoom ? this.selectedRoom.id : this.deviceConfig.room_id,
                date: date,
                duration: this.duration
            }
            roomService.getAvailabilitySlots(payload).then((res) => {
                this.slots = res.slots;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.loading = false;
            });
        },
        getServices() {
            serviceService.getAll("?pagination=off")
                .then((res) => {
                    const data = res.data.map((el) => ({
                        label: el.name,
                        value: el.id,
                        icon: el.icon,
                        qty: 0
                    }));
                    this.services = data;
                })
                .catch((error) => {
                    console.log('error fetching ', error);
                    this.showErrorNotification(this.$t('Meetix.NotFetchServices'));
                });
        },
        getEquipements() {
            equipmentService.getAll("?pagination=off")
                .then((res) => {
                    const data = res.data.map((el) => ({
                        label: el.name,
                        value: el.id,
                        icon: el.icon,
                        qty: 0
                    }));
                    this.equipments = data;
                })
                .catch((error) => {
                    console.log('error fetching ', error);
                    this.showErrorNotification(this.$t('Meetix.NotFetchEquip'));
                }).finally(() => {
                    // this.loading = false;
                });
        },
        resetQty() {
            this.equipments = this.equipments.map((el) => ({
                ...el,
                qty: 0
            }));

            this.services = this.services.map((el) => ({
                ...el,
                qty: 0
            }));
        },
        handleSelectDate(date) {
            this.date = moment(date).format("YYYY-MM-DD");
            this.selectedSlot = null;
            this.getSlots(moment(date).format("YYYY-MM-DD"))
        },
        selectSlot(slot) {
            this.selectedSlot = slot;
        },
        getUserById() {
            let datetime = moment(this.date).format("DD MMMM YYYY") + " " + this.selectedSlot;
            let payload = {
                start: this.date + " " + moment(datetime).format("HH:mm:ss"),
                end: this.date + " " + moment(datetime).add(this.info.durationMeet, 'minutes').format("HH:mm:ss"),
            }
            this.loading = true;
            userService.getAll("?pagination=off&keys=ad_integration&values=" + this.info.employeeId).then((resUser) => {
                if (resUser.data.length != 0) {
                    roomService.getAvailability(payload).then((res) => {
                        if (res.data.length != 0) {
                            this.employee = resUser.data[0];
                            this.showInfoSelection = false;
                            this.showValidation = true;
                            this.currentStep += 1;
                        } else {
                            this.showErrorNotification(this.$t('Meetix.RoomOccupiedInterval'));
                        }
                    }).catch((error) => {
                        console.log("error api : get products", error);
                    });

                } else {
                    this.showErrorNotification(this.$t('Meetix.NoUser'));
                }
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.loading = false;
            });
        },
        createReservation() {
            this.loading = true;
            let datetime = moment(this.date).format("DD MMMM YYYY") + " " + this.selectedSlot;
            let payload = {
                user_id: this.employee.id,
                start: this.date + " " + moment(datetime).format("HH:mm:ss"),
                end: this.date + " " + moment(datetime).add(this.info.durationMeet, 'minutes').format("HH:mm:ss"),
                room_id: this.selectedRoom ? this.selectedRoom.id : this.deviceConfig.room_id,
                participants: this.info.selectedPart.map(el => el.value).join(","),
                motif: this.info.meetReason,
                equipments: this.info.selectedEquip.map(el => el.value + "-qte:" + el.qty).join(","),
                services: this.info.selectedServices.map(el => el.value + "-qte:" + el.qty).join(","),
                periodical_settings: null,
                periodical: 0
            }
            if (this.info.allowPeriodical) {
                payload.periodical = this.info.allowPeriodical ? 1 : 0;
                payload.periodical_settings = {
                    type: this.info.type,
                    endDate: this.info.endDate,
                    time: this.selectedSlot,
                    duration: moment.utc(this.info.durationMeet * 60 * 1000).format('HH:mm')
                }
                if (this.info.type == 'monthly') {
                    payload.periodical_settings.daysOfWeek = moment(this.date).format("DD");
                    payload.periodical_settings.periodicity = this.info.periodicity
                }
                else if (this.info.type == 'yearly') {
                    payload.periodical_settings.daysOfWeek = moment(this.date).format("MM-DD");
                    payload.periodical_settings.periodicity = this.info.periodicity
                }
                //  else if(this.info.type == 'weekly') {
                //     payload.periodical_settings.daysOfWeek = this.info.daysOfWeek.map((el) => el.value);   
                //     payload.periodical_settings.periodicity = 1;
                // }
                else {
                    payload.periodical_settings.daysOfWeek = this.info.daysOfWeek.map((el) => el.value);
                    payload.periodical_settings.periodicity = 1;
                }
                payload.periodical_settings = JSON.stringify(payload.periodical_settings);
            }
            reservationService.create(payload).then((res) => {
                this.showValidation = false;
                this.showStatus = true;
                this.currentStep += 1;
                this.selectedRoom = null;
                localStorage.removeItem('selectedRoom');
                setTimeout(() => {
                    router.push("/home");
                }, 4000);
            }).catch((error) => {
                console.log("error api : ", error);
                if (error == 400) {
                    this.showErrorNotification(this.$t('Meetix.RoomOccupied'));
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        goBack(step) {
            this.currentStep = Number(step);
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        getRoomAvailabilityPeriod() {
            this.loading = true;
            let payload = {
                start_date: this.date,
                end_date: this.info.endDate,
                start_time: this.selectedSlot,
                duration: this.info.durationMeet,
                capacity: this.info.selectedPart.length
            }
            if (this.info.type == 'monthly' || this.info.type == 'yearly') {
                payload.type = this.info.type;
                payload.periodicity = this.info.periodicity;
            } else {
                payload.weekDays = this.info.daysOfWeek.map((el) => el.value).join(',');
            }
            roomService.getRoomAvailabilityPeriod(payload).then((res) => {
                this.rooms = res.rooms.filter((el) => {
                    if (el.id == (this.selectedRoom ? this.selectedRoom.id : this.deviceConfig.room_id)) {
                        if (el.periodical_availability.length != 0) {
                            const index = el.periodical_availability.find((el) =>
                                el.availability[0].active == 0
                            )
                            if (index) {
                                this.showErrorNotification(this.$t('Meetix.PeriodicalNotFound'));
                                this.$refs.infoSelection.showNotAvailable(el.periodical_availability);
                                this.loading = false;
                            } else {
                                this.getUserById();
                            }
                        }
                    }
                });
            }).catch((error) => {
                this.showErrorNotification(this.$t('Meetix.ErrorOccured'));
                console.log("error api : ", error);
                this.loading = false;
            }).finally(() => {
                setTimeout(() => {
                    // this.loading = false;
                }, 500);
            });
        },

    }
};
</script>

<style scoped>
@keyframes smooth-appear {
    to {
        padding-top: 0;
        opacity: 1;
    }
}

.container {
    opacity: 0;
    padding-top: 120px;
    animation: smooth-appear 0.5s ease forwards;
}

.container-text {
    opacity: 0;
    animation: smooth-appear 1s ease forwards;
}
</style>