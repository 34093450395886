<template>
    <div v-if="active" class="fixed w-screen h-screen z-50 flex justify-center items-center">

        <div
            class="bg-[#3D3D3D] relative z-50 text-white px-6 py-8 w-[70vw] min-h-[70vh] flex flex-col justify-between">
            <span class="absolute right-6 top-2 scale-[240%] z-50" @click="toggleModal">&times;</span>
            <div v-if="!showRooms" class="flex flex-col gap-3 py-4 mx-8">
                <div class="flex w-full gap-5">
                    <div class="w-full flex flex-col items-start gap-2">
                        <span>{{ $t('Meetix.StartDate') }}</span>
                        <VueDatePicker v-model="startDate" :placeholder="$t('Meetix.SelectDate')"
                            :enable-time-picker="false" :disabled-week-days="[6, 0]" :min-date="new Date()" dark>
                        </VueDatePicker>
                    </div>
                    <div class="w-full flex flex-col items-start gap-2">
                        <span>{{ $t('Meetix.StartTime') }}</span>
                        <div class="flex gap-2 w-full">
                            <!-- <button @click="manipulateTime('substract')"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconMinus :size="50" :color="'#fff'" />
                            </button>
                            <div class="bg-[#272727] h-16 flex-1 text-xl flex items-center px-4 max-md:w-32 ">
                                <p class="text-nowrap min-w-2 overflow-hidden">{{ displayTime }}</p>
                            </div>
                            <button @click="manipulateTime('add')"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconPlus :size="50" :color="'#fff'" />
                            </button> -->
                            <div class="bg-[#272727] h-16 flex-1 text-xl flex items-center px-4 max-md:w-32">
                                <input type="text" v-model="startTimeHour"
                                    @input="moveFocus('startTimeHour', 'startTimeMin')"
                                    class="bg-[#272727] w-[35px] text-center text-3xl" placeholder="--">
                                <p class="text-nowrap min-w-4 overflow-hidden">{{ "  :  " }}</p>
                                <input ref="startTimeMin" type="text" v-model="startTimeMin"
                                    @input="checkUnfocus('startTimeMin')"
                                    class="bg-[#272727] w-[35px] text-center text-3xl" placeholder="--">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex w-full gap-5">
                    <div class="w-full flex flex-col items-start gap-2">
                        <span>{{ $t('Meetix.MeetDuration') }}</span>
                        <div class="flex gap-2 w-full">
                            <button @click="manipulateDuration('substract')"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconMinus :size="50" :color="'#fff'" />
                            </button>
                            <div class="bg-[#272727] h-16 flex-1 text-2xl flex items-center px-4 max-md:w-32 ">
                                <p class="text-nowrap min-w-2 overflow-hidden">{{ displayDuration }}</p>
                            </div>
                            <button @click="manipulateDuration('add')"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconPlus :size="50" :color="'#fff'" />
                            </button>
                        </div>
                    </div>
                    <div class="w-full flex flex-col items-start gap-2">
                        <span>{{ $t('Meetix.NumberParticipant') }}</span>
                        <div class="flex gap-2 w-full">
                            <button @click="removePart()"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconMinus :size="50" :color="'#fff'" />
                            </button>
                            <div class="bg-[#272727] h-16 flex-1 flex items-center px-4 max-md:w-32 ">
                                <p class="text-nowrap min-w-2 overflow-hidden text-3xl">{{ numberPart }}</p>
                            </div>
                            <button @click="addPart()"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconPlus :size="50" :color="'#fff'" />
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="flex w-full gap-5">
                    <div class="w-full flex flex-col items-start gap-2">
                        <span>{{ $t('Meetix.NumberParticipant') }}</span>
                        <div class="flex gap-2 w-full">
                            <button @click="removePart()"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconMinus :size="50" :color="'#fff'" />
                            </button>
                            <div class="bg-[#272727] h-16 flex-1 flex items-center px-4 max-md:w-32 ">
                                <p class="text-nowrap min-w-2 overflow-hidden text-3xl">{{ numberPart }}</p>
                            </div>
                            <button @click="addPart()"
                                class="bg-[#272727] h-full w-14 flex justify-center items-center">
                                <iconPlus :size="50" :color="'#fff'" />
                            </button>
                        </div>
                    </div>
                    <div class="flex w-full gap-5">
                        <div class="w-full flex flex-col items-start gap-2 text-left">
                            <span>{{ $t('Meetix.WeekDays') }}</span>
                            <VueMultiselect v-model="selectedWeekDays" :options="weekDays" :multiple="true"
                                :internal-search="false" :close-on-select="false" :placeholder="''"
                                :showNoOptions="false" :hideSelected="true" label="label" track-by="label"
                                style="width: 100%; height: 100%; border: none;">
                                <template #tag="{ option, remove }"><span
                                        class="custom__tag flex w-fit items-center gap-2"><span>{{ option.label
                                            }}</span><span class="custom__remove" @click="remove(option)">
                                            <IconCloseFill :size="20" :color="'#fff'" />
                                        </span></span></template>
</VueMultiselect>
</div>
</div>
</div> -->
                <!-- <div class="flex justify-start text-xl mt-4 gap-4">
                    <select id="selectDate" v-model="date" :placeholder="$t('Meetix.All')"
                        class="bg-[#212121] h-16 px-4 w-full basis-1/2">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select>
                    <div class="flex basis-1/2">
                        <div @click="chooseOption('available')" class="py-3 px-2 rounded-l-md basis-1/2"
                            :class="option == 'available' ? 'bg-white text-gray-900' : 'border-2 border-white'">{{
                                $t('Meetinx.Available') }}</div>
                        <div @click="chooseOption('all')" class="py-3 px-2 rounded-r-md basis-1/2"
                            :class="option == 'all' ? 'bg-white text-gray-900' : 'border-2 border-white'">{{
                            $t('Meetinx.All') }}</div>
                    </div>
                </div> -->
            </div>
            <div v-if="!showRooms">
                <button @click="getRoomAvailabilityPeriod" class="py-3 px-24"
                    :class="validateFields ? 'bg-[#FBB601]' : 'bg-[#727171]'">{{ $t('Meetix.Confirm')
                    }}</button>
            </div>

            <!--  -->
            <div v-if="showRooms" class="flex flex-col gap-5 py-4 mx-8 items-start">
                <span class="text-xl">{{ $t('Meetix.AvailableRooms') }}</span>
                <div v-if="rooms.length != 0" class=" overflow-auto h-[45vh]">
                    <div class="flex gap-5 flex-wrap">
                        <div @click="selectRoom(item)" v-for="(item, index) in rooms" :key="index"
                            class="flex text-xl h-fit">
                            <div class="py-4 px-6 h-fit"
                                :class="selectedRoom.id == item.id ? 'bg-[#ffffff] text-gray-900' : 'bg-[#4DAD4B]'">{{
                                    item.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span>{{ $t('Meetix.NoRoomsAvail') }}</span>
                </div>
            </div>

            <div v-if="showRooms && rooms.length != 0">
                <button @click="bookRoom" class="py-3 px-24"
                    :class="selectedRoom.id ? 'bg-[#FBB601]' : 'bg-[#727171]'">{{
                        $t('Meetix.BookRoom')
                    }}</button>
            </div>
            <div v-else-if="showRooms && rooms.length == 0">
                <button @click="toggleModal" class="py-3 px-24 bg-[#FBB601]">{{ $t('Meetix.Return')
                    }}</button>
            </div>

            <div v-if="loading"
                class="bg-[#3D3D3D] absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <svg width="88" height="88" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" :stroke="color">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18"
                                    dur="1s" repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
        <div @click="toggleModal" class="fixed h-screen w-screen top-0 left-0 backdrop-blur-sm bg-[#1e1e1e80]"></div>
    </div>
</template>

<script>
import iconPlus from "@/components/icons/iconPlus.vue";
import iconMinus from "@/components/icons/iconMinus.vue";
import { roomService } from "@/_services";
import moment, { duration } from 'moment';
import VueMultiselect from 'vue-multiselect'
import IconCloseFill from "./icons/iconCloseFill.vue";
import router from "@/router";

export default {
    name: "LbrxSearchRoomPopup",
    components: {
        iconPlus,
        iconMinus,
        // VueMultiselect,
        // IconCloseFill
    },
    props: {
        active: {
            required: true,
            value: Boolean
        },
        duration: {
            required: true,
            value: Number
        },
        color: {
            required: true,
            value: String
        }
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            startTime: moment().set({ hour: 8, minute: 0, second: 0 }),
            startTimeHour: "",
            startTimeMin: "",
            rooms: [],
            start: null,
            numberPart: 1,
            durationMeet: 0,
            loading: false,
            showRooms: false,
            selectedRoom: {},
            selectedWeekDays: [],
            weekDays: [
                { label: "Monday", value: "Monday" },
                { label: "Tuesday", value: "Tuesday" },
                { label: "Wednesday", value: "Wednesday" },
                { label: "Thursday", value: "Thursday" },
                { label: "Friday", value: "Friday" }
            ]
        };
    },
    created() {
        this.durationMeet = this.duration;
    },
    computed: {
        displayDuration() {
            const minutes = this.durationMeet % 60;
            const hours = Math.floor(this.durationMeet / 60);
            if (hours != 0)
                return hours + " " + this.$t('Meetix.Hours') + " " + minutes + " " + this.$t('Meetix.Minutes');
            else
                return minutes + " " + this.$t('Meetix.Minutes');
        },
        validateFields() {
            return (this.startDate != null && this.startTimeHour.length == 2 && this.startTimeMin.length == 2 && this.duration != 0)
        },
        displayTime() {
            return moment(this.startTime).format("HH:mm")
        }
    },
    methods: {
        moveFocus(currentInput, nextInput) {
            if (this[currentInput].length == 2) {
                this.$refs[nextInput].focus();
            } else if (this[currentInput].length > 2) {
                this[currentInput] = "";
            }
        },
        checkUnfocus(currentInput) {
            if (this[currentInput].length == 2) {
                this.$refs[currentInput].blur();  // Unfocus the input
            } else if (this[currentInput].length > 2) {
                this[currentInput] = "";
            }
        },
        toggleModal() {
            this.rooms = [];
            this.startDate = null;
            this.endDate = null;
            this.startTime = moment().set({ hour: 8, minute: 0, second: 0 });
            this.startTimeHour = "";
            this.startTimeMin = "";
            this.start = null;
            this.numberPart = 1;
            this.durationMeet = this.duration;
            this.showRooms = false;
            this.selectedRoom = {};
            this.selectedWeekDays = [];
            this.$emit("toggleModal");
        },
        chooseOption(option) {
            this.option = option;
        },
        addPart() {
            if (this.numberPart <= 30)
                this.numberPart += 1;
        },
        removePart() {
            if (this.numberPart > 1)
                this.numberPart -= 1;
        },
        manipulateDuration(type) {
            if (type == "add") {
                this.durationMeet += this.duration;
            } else if (type == "substract") {
                if (this.durationMeet > this.duration) {
                    this.durationMeet -= this.duration;
                }
            }
        },
        getRoomAvailabilityPeriod() {
            if (!this.validateFields)
                return;
            this.loading = true;
            this.start = moment(this.startDate).format("YYYY-MM-DD") + " " + this.startTimeHour+":"+this.startTimeMin;
            let payload = {
                start: this.start,
                end: moment(this.start + ":00").add(this.durationMeet, 'minutes').format("YYYY-MM-DD HH:mm"),
                capacity: this.numberPart
            }
            roomService.getAvailability(payload).then((res) => {
                this.rooms = res.data;
                this.showRooms = true;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            });
        },
        selectRoom(item) {
            this.selectedRoom = item;
        },
        bookRoom() {
            if (this.selectedRoom.id) {
                this.selectedRoom.selectedTime = this.start;
                this.selectedRoom.selectedDuration = this.durationMeet;
                localStorage.setItem("selectedRoom", JSON.stringify(this.selectedRoom));
                this.toggleModal();
                window.dispatchEvent(new CustomEvent('room-changed', {
                    detail: {
                        storage: {}
                    }
                }));
                if (this.$route.name == "Booking")
                    location.reload();
                else
                    router.push('/booking');
            }
        },
        manipulateTime(type) {

            if (type == "add") {
                const time = moment(this.startTime, 'HH:mm');
                const newTime = time.add(this.duration, 'minutes');
                this.startTime = newTime;
            } else if (type == "substract") {
                const time = moment(this.startTime, 'HH:mm');
                const newTime = time.subtract(this.duration, 'minutes');
                this.startTime = newTime;
            }
        }
    }
};
</script>

<style>
.dp__pointer {
    height: 64px;
}

.dp__input {
    background-color: #272727 !important;
}

.dp__menu {
    /* position: fixed; */
}
</style>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: aqua;

}

input:focus {
    outline: none;
}
</style>