<template>
    <div v-if="timer" class="circle" :style="{ '--clr': color }">
        <div class="absolute bg-[#510300] w-[173px] h-[180px] top-2 rounded-full left-6"></div>
        <div class="timer" :style="{ 'color': color }">
            <vue-countdown class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                :time="waitTime * 1000" :transform="transformSlotProps" v-slot="{ minutes, seconds, hours }">
                {{ hours != '00' ? hours+":" : "" }}{{ minutes }}:{{ seconds }}
            </vue-countdown>
        </div>
        <svg>
            <circle r="90" cy="100" cx="100"></circle>
            <circle :class="{ 'stroke-animation': modelValue }" r="90" cy="100" cx="100" id="counter"></circle>
        </svg>
    </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from 'moment';
export default {
    name: 'LbrxCountdown',
    components: {
        VueCountdown
    },
    props: {
        color: String,
        timer: Number,
        modelValue: Boolean,
        room: Object,
        diffTime: String
    },
    emits: ['counter-ended'],
    data() {
        return {
            currentTimer: 0,
            waitTime: 0,
            timerInterval: null,
            originalCircleLength: -565
        }
    },
    created() {
        this.currentTimer = this.timer;
        this.waitTime = this.diffTime;
    },
    watch: {
        modelValue(newValue) {
            if (newValue) {
                this.timerInterval = setInterval(() => {
                    this.currentTimer--;
                    if (this.currentTimer <= 0) {
                        this.currentTimer = 0;
                        clearInterval(this.timerInterval);
                        setTimeout(() => {
                            this.$emit('counter-ended');
                        }, 200)
                    }
                }, 1000);
            } else {
                // this.currentTimer = this.timer
            }
        }
    },
    computed: {
        animationDuration() {
            return `${this.currentTimer}s`;
        },
        getCircleLength() {
            return (this.waitTime * this.originalCircleLength) / this.getMeetDuration();
        }
    },
    methods: {
        getMeetDuration() {
            const start = moment(this.room.instantReservations[0].start, 'YYYY-MM-DD HH:mm:ss');
            const end = moment(this.room.instantReservations[0].end, 'YYYY-MM-DD HH:mm:ss');
            return end.diff(start, 'seconds');
        },
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    }
}
</script>

<style scoped>
.circle {
    margin-top: 3vmin;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

svg {
    position: relative;
    height: 210px;
    /* Increased size */
    width: 210px;
    /* Increased size */
    transform: rotate(270deg);
}

svg circle {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke: var(--clr);
    stroke-width: 8.7;
    transform: translate(10px, 10px);
    /* Adjusted for larger size */
}

@keyframes dash-offset-animation {
    from {
        stroke-dashoffset: v-bind(getCircleLength);
        /* Adjusted for larger circle */

    }

    to {
        stroke-dashoffset: 0;
    }
}

.stroke-animation {
    animation: dash-offset-animation linear forwards;
    animation-duration: v-bind(animationDuration);
}

svg circle:nth-child(2) {
    stroke-width: 10;
    stroke: #510300;
    stroke-dasharray: 565;
    /* Adjusted for larger circle */
    stroke-dashoffset: -565;
    /* Adjusted for larger circle */
}

#seconds {
    height: 55px;
    width: 70px;
    letter-spacing: 1px;
}

.timer {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    left: 10px;
    margin-bottom: 10px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    color: green;
    font-size: 2.8em;
    line-height: 2em;
}

#seconds {
    margin-bottom: -40%;
}
</style>