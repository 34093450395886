<template>
    <svg :width="size" :height="size" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M96.2499 15.771C90.1731 13.8623 83.7067 12.8334 76.9999 12.8334C41.5616 12.8334 12.8333 41.5618 12.8333 77.0001C12.8333 112.438 41.5616 141.167 76.9999 141.167C112.438 141.167 141.167 112.438 141.167 77.0001C141.167 70.2933 140.138 63.8269 138.229 57.7501" :stroke="color" stroke-width="11.5" stroke-linecap="round"/>
<path d="M54.5415 60.9583L76.9998 83.4167L134.751 19.25" :stroke="color" stroke-width="11.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconValidation',
  props: {
    size: Number,
    color: String
  }
}
</script>