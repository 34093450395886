<template>
    <a-drawer v-model:open="sliderOpened" class="custom-class" root-class-name="root-class-name"
        :root-style="{ color: 'blue' }" style="color: white; background-color: #3D3D3D;" placement="right"
        :closable="false" width="40%">
        <div class="flex flex-col px-2 h-full justify-between">
            <div class="flex flex-col gap-14">
                <div class="flex text-white justify-between items-center">
                    <span class="text-4xl font-semibold">{{ $t('Meetix.Menu').toUpperCase() }}</span>
                    <div class="m-2" @click="toggleSlider">
                        <iconCloseLight :size="40" :color="'#fff'" />
                    </div>
                </div>
                <div class="flex flex-col gap-4 text-3xl">
                    <div v-if="route.currentRoute.name == 'Booking'" @click="goRouter('/home')" class="flex justify-between items-center py-5">
                        <span>{{ $t('Meetix.HomePage') }}</span>
                        <iconHome :size="36" :color="'#fff'" />
                    </div>
                    <div v-else @click="goRouter('/booking')" class="flex justify-between items-center py-5">
                        <span>{{ $t('Meetix.BookRoom') }}</span>
                        <iconAddBook :size="36" :color="'#fff'" />
                    </div>
                    <div class="h-[2px] w-full bg-[#FFFFFF33]"></div>
                    <div @click="refresh" class="flex justify-between items-center py-5">
                        <span>{{ $t('Meetix.Refresh') }}</span>
                        <iconRefresh :size="36" :color="'#fff'" />
                    </div>
                    <div class="h-[2px] w-full bg-[#FFFFFF33]"></div>
                    <div @click="toggleAssistPopup" class="flex justify-between items-center py-5">
                        <span>{{ $t('Meetix.TechnicalAssistance') }}</span>
                        <iconPhone :size="36" :color="'#fff'" />
                    </div>
                    <div class="h-[2px] w-full bg-[#FFFFFF33]"></div>
                    <div @click="toggleAboutPopup" class="flex justify-between items-center py-5">
                        <span>{{ $t('Meetix.About') }}</span>
                        <iconInfo :size="36" :color="'#fff'" />
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-8 mb-5 text-3xl">
                <div class="h-[2px] w-full bg-[#FFFFFF33]"></div>
                <div @click="toggleLogoutPopup" class="flex justify-between items-center text-[#888888]">
                    <span>{{ $t('Meetix.Logout') }}</span>
                    <iconLogout :size="36" :color="'#888888'" />
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import iconCloseLight from "@/components/icons/iconCloseLight.vue";
import iconAddBook from "@/components/icons/iconAddBook.vue";
import iconRefresh from "@/components/icons/iconRefresh.vue";
import iconPhone from "@/components/icons/iconPhone.vue";
import iconInfo from "@/components/icons/iconInfo.vue";
import iconLogout from "@/components/icons/iconLogout.vue";
import iconHome from "@/components/icons/iconHome.vue";
import router from "@/router";
export default {
    name: "LbrxSlider",
    components: {
        iconCloseLight,
        iconAddBook,
        iconRefresh,
        iconPhone,
        iconInfo,
        iconLogout,
        iconHome
    },
    props: {
        route: {
            required: true,
            value: Object
        }
    },
    data() {
        return {
            sliderOpened: false
        };
    },
    methods: {
        getSelectedStep(step) {
            return {
                backgroundColor: step == this.currentStep ? this.primaryColor : step < this.currentStep ? "#38B02E" : "#575757"
            }
        },
        toggleSlider() {
            this.sliderOpened = !this.sliderOpened;
        },
        refresh() {
            window.location.reload();
            this.toggleSlider()
            // this.$emit("toggleLoader")
        },
        goRouter(route) {
            this.toggleSlider()
            router.push(route);
        },
        toggleLogoutPopup() {
            this.toggleSlider();
            this.$emit("toggleLogoutPopup")
        },
        toggleAssistPopup() {
            this.toggleSlider();
            this.$emit("toggleAssistPopup")
        },
        toggleAboutPopup() {
            this.toggleSlider();
            this.$emit("toggleAboutPopup")
        }
    }
};
</script>

<style scoped></style>