<template>
    <div class="flex flex-col h-[80vh] justify-around w-full text-white overflow-y-auto ">
        <span class="text-2xl">{{ $t('Meetix.BookValidation') }}</span>
        <div v-if="info.selectedEquip.length == 0 && info.selectedServices.length == 0"
            class="flex flex-col px-[10rem]">
            <div class="bg-[#D9D9D930] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Room').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ displayRoomName() }}</span>
                </div>
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Organizer').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ employee.first_name + " " + employee.last_name }}</span>
                </div>
            </div>
            <div class="bg-[#D9D9D94A] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.DateTime').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ displayDateTime }}</span>
                </div>
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.Duration').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ displayDurtaion }}</span>
                </div>
            </div>
            <div class="bg-[#D9D9D930] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.MeetReason').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ info.meetReason }}</span>
                </div>
                <div class="flex flex-col w-full gap-3">
                    <span class="text-2xl text-[#FBB601]">{{ $t('Meetix.ParticipantNumber').toUpperCase() }}</span>
                    <span class="text-3xl font-semibold">{{ info.selectedPart.length + " " + $t('Meetix.Participants')
                        }}</span>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col px-[10rem] h-[57vh] overflow-auto">
            <div class="bg-[#D9D9D930] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.Room').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ displayRoomName() }}</span>
                </div>
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.Organizer').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ employee.first_name + " " + employee.last_name }}</span>
                </div>
            </div>
            <div class="bg-[#D9D9D94A] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.DateTime').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ displayDateTime }}</span>
                </div>
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.Duration').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ displayDurtaion }}</span>
                </div>
            </div>
            <div class="bg-[#D9D9D930] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.MeetReason').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ info.meetReason }}</span>
                </div>
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.ParticipantNumber').toUpperCase() }}</span>
                    <span class="text-2xl font-semibold">{{ info.selectedPart.length + " " + $t('Meetix.Participants')
                        }}</span>
                </div>
            </div>
            <div class="bg-[#D9D9D930] flex justify-center text-left gap-10 w-full px-16 py-6">
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.Equipments').toUpperCase() }}</span>
                    <span class="text-2xl">{{ info.selectedEquip.map(el => el.label + " x" + el.qty).join(", ") }}</span>
                    <span v-if="info.selectedEquip.length == 0" class="text-2xl">{{ $t('Meetix.NoEquipments') }}</span>
                </div>
                <div class="flex flex-col w-full gap-1">
                    <span class="text-xl text-[#FBB601]">{{ $t('Meetix.Services').toUpperCase() }}</span>
                    <span class="text-2xl">{{ info.selectedServices.map(el => el.label + " x" + el.qty).join(", ") }}</span>
                    <span v-if="info.selectedServices.length == 0" class="text-2xl">{{ $t('Meetix.NoServices') }}</span>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <button @click="nextStep"
                class="flex items-center border-2 border-white px-10 py-3 text-3xl font-semibold gap-10">{{
                    $t('Meetix.SendRequest').toUpperCase() }}
            </button>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "LbrxInfoValidation",
    components: {
    },
    props: {
        room: {
            required: true,
            value: Object
        },
        info: {
            required: true,
            value: Object
        },
        employee: {
            required: true,
            value: Object
        },
        selectedSlot: {
            required: true,
            value: Object
        },
        date: {
            required: true,
            value: String
        }
    },
    data() {
        return {
        };
    },
    computed: {
        displayDurtaion() {
            const minutes = this.info.durationMeet % 60;
            const hours = Math.floor(this.info.durationMeet / 60);
            if (hours == 0)
                return minutes + " " + this.$t('Meetix.Minutes');
            else if (minutes == 0)
                return hours + " " + this.$t('Meetix.Hours');
            else
                return hours + " " + this.$t('Meetix.Hours') + " " + minutes + " " + this.$t('Meetix.Minutes');
        },
        displayDateTime() {
            return moment(this.date).locale(this.$i18n.locale).format("DD MMMM YYYY") + " " + this.$t('Meetix.At') + " " + this.selectedSlot;
        }
    },
    methods: {
        nextStep() {
            this.$emit("nextStep", "status");
        },
        displayRoomName() {
            return localStorage.getItem('selectedRoom') ? JSON.parse(localStorage.getItem('selectedRoom')).name : this.room.name;
        },
    }
};
</script>

<style scoped></style>