<template>
    <div class="flex flex-col h-full justify-around text-[#A7A7A7] overflow-hidden">
        <div class="flex justify-between items-center my-8 px-8">
            <div @click="toggleWeek('previous')"
                class="bg-[#FAB401] h-fit px-3 py-3 max-lg:p-2 rounded-xl flex items-center">
                <iconArrowLeft2 :size="40" :color="'#fff'" />
            </div>
            <div v-for="(item, index) in weekDates" :key="index"
                class="relative flex flex-col w-[11rem] max-lg:w-[8rem]">
                <div v-if="selectedColumn == index"
                    class="absolute h-screen w-full top-[-16px] max-lg:w-[120px] bg-[#FFFFFF1A]"
                    :class="selectedColumn == 0 ? 'left-[5px] w-[171px]' : selectedColumn == 1 ? 'left-[3px] w-[171px]' : selectedColumn == 2 ? 'w-[175px]' : selectedColumn == 3 ? 'w-[171px]' : selectedColumn == 4 ? 'w-[169px]' : ''">
                </div>
                <span class="text-3xl max-lg:text-lg">{{ item.day.toUpperCase() }}</span>
                <span class="inria-light text-3xl max-lg:text-lg">{{ item.date }}</span>
            </div>
            <div @click="toggleWeek('next')"
                class="bg-[#FAB401] h-fit px-3 py-3 max-lg:p-2 rounded-xl flex items-center">
                <iconArrowRight2 :size="40" :color="'#fff'" />
            </div>
        </div>
        <div class="flex flex-col flex-1 gap-8 max-lg:gap-9 mt-4 overflow-auto px-8 no-scrollbar">
            <div v-for="(itemSlots, indexSlots) in timeSlots" :key="indexSlots"
                class="relative flex items-center justify-between">
                <span class="text-[1.4rem] leading-8 w-16 max-lg:text-xl">{{ itemSlots }}</span>
                <div class="absolute flex w-full gap-8">
                    <div class="w-[81px]"></div>
                    <div id="currentTime" v-if="checkIfCurrentTime(timeSlots[indexSlots], timeSlots[indexSlots + 1])"
                        class="w-[100%] max-lg:ml-3 h-[2px] bg-[#921B17CC]"
                        :style="cureentTimeBarPosition(timeSlots[indexSlots])">
                    </div>
                </div>
                <div class="w-full ml-12 max-lg:ml-3 h-[2px] bg-[#A6A6A65E]">
                </div>
                <div class="absolute h-full w-full">
                    <div class="flex justify-between items-center max-lg:my-[16px] my-[18px]">
                        <!-- just for layout positionoing -->
                        <div class="bg-[#FAB401] h-fit px-3 py-3 max-lg:p-2 rounded-xl flex items-center invisible">
                            <iconArrowLeft2 :size="40" :color="'#fff'" />
                        </div>
                        <div v-for="(itemDate, indexDate) in weekDates" :key="indexDate"
                            class="relative flex flex-col w-[10.5rem] max-lg:w-[8rem]" @click="selectColumn(indexDate)">

                            <div v-if="displayDataInCalendar(itemDate.completeFormat, itemSlots)?.id"
                                class="bg-[#fff] text-xl px-5 absolute py-1 w-full rounded-lg text-left flex flex-col justify-between z-10 overflow-hidden"
                                :style="'height:' + displayDataInCalendar(itemDate.completeFormat, itemSlots).heightPixels * displayDataInCalendar(itemDate.completeFormat, itemSlots).height + 'px;' + checkStatusBooking(displayDataInCalendar(itemDate.completeFormat, itemSlots).approved) + checkIfCurrentDateIsBetweenBG(displayDataInCalendar(itemDate.completeFormat, itemSlots).start, displayDataInCalendar(itemDate.completeFormat, itemSlots).end)"
                                :class="'top-[-33px]'"
                                @click="toggleSliderCalendar(displayDataInCalendar(itemDate.completeFormat, itemSlots))">
                                <div class="flex flex-col">
                                    <span class="line-clamp-1"
                                        :class="checkIfCurrentDateIsBetween(displayDataInCalendar(itemDate.completeFormat, itemSlots).start, displayDataInCalendar(itemDate.completeFormat, itemSlots).end) || displayDataInCalendar(itemDate.completeFormat, itemSlots).approved == 0 ? 'text-white' : 'text-[#FAB401]'">{{
                                            displayDataInCalendar(itemDate.completeFormat,
                                                itemSlots).motif || "--"
                                        }}</span>
                                    <span class="text-lg"
                                        :class="checkIfCurrentDateIsBetween(displayDataInCalendar(itemDate.completeFormat, itemSlots).start, displayDataInCalendar(itemDate.completeFormat, itemSlots).end) || displayDataInCalendar(itemDate.completeFormat, itemSlots).approved == 0 ? 'text-white' : 'text-[#3D3D3D]'">{{
                                            displayDataInCalendar(itemDate.completeFormat,
                                                itemSlots)?.user.first_name + " " +
                                            displayDataInCalendar(itemDate.completeFormat,
                                                itemSlots)?.user.last_name }}</span>
                                </div>
                                <span class="font-semibold"
                                    :class="checkIfCurrentDateIsBetween(displayDataInCalendar(itemDate.completeFormat, itemSlots).start, displayDataInCalendar(itemDate.completeFormat, itemSlots).end) ? 'text-[#E41B13]' : displayDataInCalendar(itemDate.completeFormat, itemSlots).approved == 1 ? 'text-[#38B02E]' : 'text-[#FAB401]'">{{
                                        checkIfCurrentDateIsBetween(displayDataInCalendar(itemDate.completeFormat,
                                            itemSlots).start, displayDataInCalendar(itemDate.completeFormat, itemSlots).end) ?
                                            $t('Meetix.InProgress') : displayDataInCalendar(itemDate.completeFormat,
                                                itemSlots).approved == 1 ? $t('Meetix.Confirmed') : $t('Meetix.OnHoldBook')
                                    }}</span>
                            </div>
                        </div>
                        <div class="bg-[#FAB401] h-fit px-3 py-3 max-lg:p-2 rounded-xl flex items-center invisible">
                            <iconArrowRight2 :size="40" :color="'#fff'" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <LbrxSliderCalendar ref="sliderCalendarRef" :booking="selectedBooking" />
        <div class="h-24 w-full bg-[#3D3D3D] z-50"></div>
    </div>

</template>

<script>
import iconArrowLeft2 from './icons/iconArrowLeft2.vue';
import iconArrowRight2 from './icons/iconArrowRight2.vue';
import LbrxSliderCalendar from './LbrxSliderCalendar.vue';
import { nextTick } from 'vue';
import moment from 'moment';
export default {
    name: "LbrxCalendarWeek",
    components: {
        iconArrowLeft2,
        iconArrowRight2,
        LbrxSliderCalendar
    },
    props: {
        data: {
            required: true,
            value: Array,
        },
        weekDates: {
            required: true,
            value: Number,
        },
        timeSlots: {
            required: true,
            value: Number,
        },
        duration: {
            required: true,
            value: Number,
        }
    },
    data() {
        return {
            dataToDisplay: [],
            selectedBooking: {},
            selectedColumn: null
        };
    },
    methods: {
        nextStep() {
            this.$emit("nextStep", "status");
        },
        displayDataInCalendar(start, time) {

            for (const el of this.data) {
                if (moment(el.start).format('YYYY-MM-DD') == start) {
                    let nextTime = moment(start + " " + time + ":00").add(this.duration, 'minutes').format("HH:mm")
                    if (moment(el.start).format('HH:mm') >= time && moment(el.start).format('HH:mm') < nextTime) {
                        const momentStart = moment(el.start);
                        const momentEnd = moment(el.end);
                        el.height = Math.abs(momentEnd.diff(momentStart, "minutes") / this.duration);
                        el.heightPixels = (Math.abs(momentEnd.diff(momentStart, "minutes") / this.duration) / 4) + 62;
                        // console.log('eeeeee', el.height, momentEnd.diff(momentStart, "minutes", true))
                        return el;
                        // return true;

                    }
                }
            }
            nextTick(() => {
                var el = document.getElementById('currentTime');
                if (el) {
                    el.style.paddingBottom = '60vh';
                    el.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                    el.style.paddingBottom = '';
                }
            });


        },
        toggleWeek(date) {
            this.$emit("toggleWeek", date)
        },
        toggleSliderCalendar(booking) {
            this.selectedBooking = booking;
            if(this.selectedBooking.periodical_settings && (this.selectedBooking.periodical_settings.type == 'daily' || this.selectedBooking.periodical_settings.type == 'weekly')) {
                this.selectedBooking.periodical_settings.daysOfWeek = this.translateWeekdays(this.selectedBooking.periodical_settings.daysOfWeek);
            }
            this.$refs.sliderCalendarRef.toggleSlider();
        },
        translateWeekdays(weekdays) {
            return weekdays.map(day => moment(day, 'dddd').locale('fr').format('dddd'));
        },
        checkIfCurrentDateIsBetween(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const current = moment();

            return current.isBetween(start, end);
        },
        checkIfCurrentDateIsBetweenBG(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const current = moment();

            return current.isBetween(start, end) ? 'background-color: #FBB601;' : '';
        },
        checkIfCurrentTime(startDate, endDate) {
            const format = 'HH:mm';
            const start = moment(startDate, format);
            const end = moment(endDate, format);
            const current = moment();

            return current.isBetween(start, end);
        },
        checkStatusBooking(status) {
            return status == 1 ? 'background-color: #fff;' : 'background-color: #DF5E00;';
        },
        selectColumn(column) {
            this.selectedColumn = column;
        },
        cureentTimeBarPosition(startDate) {
            const format = 'HH:mm';
            const start = moment(startDate, format);
            const current = moment();
            const step = current.diff(start, 'minutes');

            const unit = (parseFloat(step) / parseFloat(this.duration)) * 100;
            // 128px is the space between each line
            const marginTop = (parseFloat(unit) / 100) * 128;
            return `margin-top: ${parseInt(marginTop)}px`;
        }
    }
};
</script>

<style scoped></style>