function server() {
    return process.env.VUE_APP_API_URL_TN;
}

export const ApiConfigs = {
    base_url: server(),
    broadcast_url: server() + "/broadcasting",
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth"
    },
    kiosk: {
        generate: "/kiosk/auth/generate",
        authCodeConfirm: "/kiosk/auth/confirm",
        logout: "/kiosk/logout"
    },
    reservation: {
        getAll: "/kiosk/reservations",
        create: "/kiosk/reservations"
    },
    room: {
        getAvailabilitySlots: "/kiosk/rooms-availability-with-slots",
        getById: "/kiosk/rooms/:id",
        getAvailability: "/kiosk/rooms-availability",
        roomsAvailabilityPeriod: "/kiosk/rooms-availability-for-period",
        getAll: "/kiosk/rooms"
    },
    user: {
        getById: "/kiosk/users/:id",
        getAll: "/kiosk/users"
    },
    service: {
        getAll: "/kiosk/services",
        create: "/kiosk/services",
        update: "/kiosk/services/:id",
        getById: "/kiosk/services/:id",
        delete: "/kiosk/services"
    },
    equipments: {
        getAll: "/equipments",
        create: "/equipments",
        update: "/equipments/:id",
        getById: "/equipments/:id",
        delete: "/equipments"
    }
};
