<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66669 24C2.66669 21.9461 2.66669 20.9191 3.12899 20.1647C3.38768 19.7426 3.7426 19.3876 4.16473 19.129C4.91914 18.6667 5.9461 18.6667 8.00002 18.6667C10.0539 18.6667 11.0809 18.6667 11.8353 19.129C12.2574 19.3876 12.6124 19.7426 12.871 20.1647C13.3334 20.9191 13.3334 21.9461 13.3334 24C13.3334 26.0539 13.3334 27.0809 12.871 27.8353C12.6124 28.2574 12.2574 28.6123 11.8353 28.871C11.0809 29.3333 10.0539 29.3333 8.00002 29.3333C5.9461 29.3333 4.91914 29.3333 4.16473 28.871C3.7426 28.6123 3.38768 28.2574 3.12899 27.8353C2.66669 27.0809 2.66669 26.0539 2.66669 24Z" :stroke="color" stroke-width="1.5"/>
<path d="M18.6667 24C18.6667 21.9461 18.6667 20.9191 19.129 20.1647C19.3877 19.7426 19.7426 19.3876 20.1647 19.129C20.9191 18.6667 21.9461 18.6667 24 18.6667C26.0539 18.6667 27.0809 18.6667 27.8353 19.129C28.2574 19.3876 28.6124 19.7426 28.871 20.1647C29.3334 20.9191 29.3334 21.9461 29.3334 24C29.3334 26.0539 29.3334 27.0809 28.871 27.8353C28.6124 28.2574 28.2574 28.6123 27.8353 28.871C27.0809 29.3333 26.0539 29.3333 24 29.3333C21.9461 29.3333 20.9191 29.3333 20.1647 28.871C19.7426 28.6123 19.3877 28.2574 19.129 27.8353C18.6667 27.0809 18.6667 26.0539 18.6667 24Z" :stroke="color" stroke-width="1.5"/>
<path d="M2.66669 7.99999C2.66669 5.94607 2.66669 4.91911 3.12899 4.1647C3.38768 3.74257 3.7426 3.38765 4.16473 3.12896C4.91914 2.66666 5.9461 2.66666 8.00002 2.66666C10.0539 2.66666 11.0809 2.66666 11.8353 3.12896C12.2574 3.38765 12.6124 3.74257 12.871 4.1647C13.3334 4.91911 13.3334 5.94607 13.3334 7.99999C13.3334 10.0539 13.3334 11.0809 12.871 11.8353C12.6124 12.2574 12.2574 12.6123 11.8353 12.871C11.0809 13.3333 10.0539 13.3333 8.00002 13.3333C5.9461 13.3333 4.91914 13.3333 4.16473 12.871C3.7426 12.6123 3.38768 12.2574 3.12899 11.8353C2.66669 11.0809 2.66669 10.0539 2.66669 7.99999Z" :stroke="color" stroke-width="1.5"/>
<path d="M18.6667 7.99999C18.6667 5.94607 18.6667 4.91911 19.129 4.1647C19.3877 3.74257 19.7426 3.38765 20.1647 3.12896C20.9191 2.66666 21.9461 2.66666 24 2.66666C26.0539 2.66666 27.0809 2.66666 27.8353 3.12896C28.2574 3.38765 28.6124 3.74257 28.871 4.1647C29.3334 4.91911 29.3334 5.94607 29.3334 7.99999C29.3334 10.0539 29.3334 11.0809 28.871 11.8353C28.6124 12.2574 28.2574 12.6123 27.8353 12.871C27.0809 13.3333 26.0539 13.3333 24 13.3333C21.9461 13.3333 20.9191 13.3333 20.1647 12.871C19.7426 12.6123 19.3877 12.2574 19.129 11.8353C18.6667 11.0809 18.6667 10.0539 18.6667 7.99999Z" :stroke="color" stroke-width="1.5"/>
</svg>
</template>

<script>

export default {
  name: 'iconMenu',
  props: {
    size: Number,
    color: String
  }
}
</script>