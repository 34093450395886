<template>
  <div class="w-full h-full flex flex-col relative">
    <div class="h-full flex">

      <img id="poster" :src="poster" alt="" style="background-repeat: no-repeat;
                background-size: cover;">

    </div>
    <div class="absolute h-full w-full flex-1">
      <div class="h-full flex">
        <div class="w-[60%]">
          <LbrxRoomStatus :status="roomStatus" :room="room" @getRoomById="getRoomById"
                          @getAllReservations="getAllReservationsNoload" :reservation="reservation"
                          :diffTime="diffTime"/>
        </div>
        <div class="w-[40%]">
          <LbrxAgendaBar :todayReservations="todayReservations"
                         :tomorrowReservations="tomorrowReservations"/>
        </div>
      </div>
    </div>
    <LbrxLoader v-if="loading" :color="'#FBB601'"/>
  </div>
</template>

<script>
import LbrxRoomStatus from "@/components/LbrxRoomStatus"
import LbrxAgendaBar from "@/components/LbrxAgendaBar"
import LbrxLoader from "@/components/LbrxLoader"
import {reservationService, roomService} from "@/_services";
import moment from 'moment';

export default {
  name: "homeIndex",
  components: {
    LbrxRoomStatus,
    LbrxAgendaBar,
    LbrxLoader
  },
  data() {
    return {
      themeTemplate: {},
      loading: false,
      device: {},
      deviceConfig: {},
      reservations: [],
      todayReservations: [],
      tomorrowReservations: [],
      room: {},
      roomStatus: "available",
      reservation: {},
      meetIncoming: false,
      diffTime: null,
      poster: "/img/default_room.jpg"
    };
  },
  created() {
    if (localStorage.getItem('selectedRoom')) {
      localStorage.removeItem('selectedRoom');
      location.reload();
    }
    if (localStorage.getItem('themeTemplate')) {
      this.themeTemplate = JSON.parse(localStorage.getItem('themeTemplate'));
    }
    if (localStorage.getItem('device')) {
      this.device = JSON.parse(localStorage.getItem('device'));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.deviceConfig = JSON.parse(this.device.config);
      }
    }
  },
  mounted() {
    //
    this.loading = true;
    // setTimeout(() => {
    //     this.loading = false;
    // }, 1000);
  },
  methods: {
    getAllReservationsNoload() {
      reservationService.getAll(`?pagination=off&keys=room_id&values=${this.deviceConfig.room_id}&start_date=${moment().format('YYYY-MM-DD')}&end_date=${moment().add(1, 'days').format('YYYY-MM-DD')}`).then((res) => {
        this.todayReservations = []
        this.tomorrowReservations = []
        this.reservations = res.data;
        const now = moment();
        let ifIncomingMeet = false;
        this.reservations.forEach((el) => {
          if (moment(el.start).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            const fifteenMinutesBeforeStartDate = moment(el.start).subtract(15, 'minutes');
            const momentResStart = moment(el.start);
            const momentStart = moment();
            if (now.isBetween(fifteenMinutesBeforeStartDate, el.start)) {
              this.meetIncoming = true;
              this.reservation = el;
              this.diffTime = Math.ceil(momentResStart.diff(momentStart, "seconds") / 60);
              this.roomStatus = "onhold";
              ifIncomingMeet = true;
            }
            if (moment(el.start).isSameOrAfter(moment()))
              this.todayReservations.push(el)
          } else if (moment(el.start).format('YYYY-MM-DD') == moment().add(1, 'days').format('YYYY-MM-DD')) {
            this.tomorrowReservations.push(el)
          }
        });
        if (!ifIncomingMeet) {
          this.meetIncoming = false;
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    getRoomById() {
      // this.loading = true;
      roomService.getById(this.deviceConfig.room_id).then((res) => {
        this.room = res.data;
        if (!this.meetIncoming)
          this.roomStatus = this.room.instantStatus;
        if (this.room.instantStatus == "occupied") {
          // this.room.diffTime =
          this.roomStatus = this.room.instantStatus;
          const instantArrayReserv = this.room.instantReservations.slice().sort((a, b) => {
            return moment(a.start).diff(moment(b.start));
          });
          const instantReserv = instantArrayReserv[0];
          this.reservation = instantReserv;
          const momentStart = moment();
          const momentResStart = moment(instantReserv.start);
          this.diffTime = Math.ceil(momentResStart.diff(momentStart, "seconds") / 60);
          if (this.diffTime <= 15 && this.diffTime > 0) {
            this.roomStatus = "onhold"
          } else {
            const momentEnd = moment(instantReserv.end);
            this.diffTime = Math.abs(Math.ceil(momentEnd.diff(momentStart, "seconds")));
          }
        }
        if (this.room.pictures.length > 0) {
          this.poster = this.room.pictures.at(0).url
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        // this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>