<template>
    <div class="flex flex-col h-full justify-around w-full text-white overflow-y-auto py-8">
        <div v-show="infoStep == 1" class="flex flex-col gap-5">
            <div class="flex justify-center text-left gap-10 mx-24">
                <div class="flex flex-col w-full">
                    <span class="text-xl my-5">{{ $t('Meetix.EmployeeId') }}</span>
                    <input class="bg-[#272727] h-16 px-4 w-full" type="text" v-model="employeeId">
                </div>
                <div class="flex flex-col w-full">
                    <span class="text-xl my-5">{{ $t('Meetix.MeetReason') }}</span>
                    <input class="bg-[#272727] h-16 px-4 w-full" type="text" v-model="meetReason">
                </div>
            </div>
            <div class="flex justify-center text-left gap-10 mx-24">
                <div class="flex flex-col w-full">
                    <span class="text-xl my-5">{{ $t('Meetix.MeetDuration') }}</span>
                    <div class="flex gap-2">
                        <button @click="manipulateDuration('substract')"
                            class="bg-[#272727] h-full w-16 flex justify-center items-center">
                            <iconMinus :size="50" :color="'#fff'" />
                        </button>
                        <div class="bg-[#272727] h-16 flex-1 flex items-center px-4 max-md:w-32 ">
                            <p class="text-nowrap min-w-2 overflow-hidden">{{ displayDuration }}</p>
                        </div>
                        <button @click="manipulateDuration('add')"
                            class="bg-[#272727] h-full w-16 flex justify-center items-center">
                            <iconPlus :size="50" :color="'#fff'" />
                        </button>
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    <span class="text-xl my-5">{{ $t('Meetix.Participants') }}</span>
                    <div class="flex gap-2">
                        <!-- <button @click="manipulateParticipants('substract')"
                            class="bg-[#272727] h-full w-16 flex justify-center items-center">
                            <iconMinus :size="50" :color="'#fff'" />
                        </button> -->

                        <!-- <div class="h-16 flex-1 flex w-full relative"> -->
                        <VueMultiselect v-model="selectedPart" :options="users" :multiple="true" :searchable="true"
                            :loading="fetching" :internal-search="false" :options-limit="10" :close-on-select="false"
                            :placeholder="$t('Meetix.selectParticipants')" :showNoOptions="false" :hideSelected="true"
                            label="label" track-by="label" style="width: 100%; height: 100%; border: none;"
                            @search-change="fetchUser">
                            <template #tag="{ option, remove }"><span
                                    class="custom__tag flex w-fit items-center gap-2"><span>{{ option.label
                                        }}</span><span class="custom__remove" @click="remove(option)">
                                        <IconCloseFill :size="20" :color="'#fff'" />
                                    </span></span></template>
                        </VueMultiselect>
                        <!-- <a-select
                                        v-model:value="selectedPart"
                                        mode="multiple"
                                        label-in-value
                                        :placeholder="$t('Meetix.selectParticipants')"
                                        style="width: 100%; line-height: 30px; overflow: hidden;"
                                        :filter-option="false"
                                        :not-found-content="fetching ? undefined : null"
                                        :options="users"
                                        @search="fetchUser"
                                        @change="handleUsersSelect"
                                        optionLineHeight={228}
                                    >
                                   
                                        <template v-if="fetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select> -->

                        <!-- </div> -->
                        <!-- <button @click="manipulateParticipants('add')"
                            class="bg-[#272727] h-full w-16 flex justify-center items-center">
                            <iconPlus :size="50" :color="'#fff'" />
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="flex mt-6 text-left items-center gap-4 mx-24">
                <label class="switch">
                    <input v-model="allowPeriodical" type="checkbox">
                    <span class="slider round"></span>
                    <span></span>
                </label>
                <span class="text-xl">{{ $t('Meetix.MakePeriodical') }}</span>
            </div>
        </div>
        <div v-show="infoStep == 2" class="flex flex-col gap-5">
            <div class="flex justify-center text-left gap-10 mx-24">
                <div class="w-full flex flex-col items-start">
                    <span class="text-xl my-5">{{ $t('Meetix.Type') }}</span>
                    <select id="selectType" v-model="typePer" @change="changeTypePer" :placeholder="$t('Meetix.Type')"
                        class="bg-[#272727] py-5 px-4 w-full basis-1/2">
                        <option value="daily">{{$t('Meetix.Daily')}}</option>
                        <option value="weekly">{{$t('Meetix.Weekly')}}</option>
                        <option value="monthly">{{$t('Meetix.Monthly')}}</option>
                        <option value="yearly">{{$t('Meetix.Annual')}}</option>
                    </select>
                </div>
                <div class="w-full flex flex-col items-start" >
                    <span class="text-xl my-5">{{ $t('Meetix.EndDate') }}</span>
                    <VueDatePicker v-model="endPer" :placeholder="$t('Meetix.SelectDate')" :enable-time-picker="false" :teleport="true" :disabled-week-days="[6, 0]" :min-date="endPerMin" dark></VueDatePicker>
                </div>
            </div>
            <div class="flex justify-center text-left gap-10 mx-24">
                <div v-if="typePer == 'monthly' || typePer == 'yearly'" class="w-full flex flex-col items-start">
                    <span class="text-xl my-5">{{ $t('Meetix.Periodicity') }}</span>
                    <div class="flex gap-2 w-full">
                        <button @click="manipulatePeriodicity('substract')"
                            class="bg-[#272727] h-full w-14 flex justify-center items-center">
                            <iconMinus :size="50" :color="'#fff'" />
                        </button>
                        <div class="bg-[#272727] h-16 flex-1 text-xl flex items-center px-4 max-md:w-32">
                            <p class="text-nowrap min-w-2 overflow-hidden">{{ periodicity }}</p>
                        </div>
                        <button @click="manipulatePeriodicity('add')"
                            class="bg-[#272727] h-full w-14 flex justify-center items-center">
                            <iconPlus :size="50" :color="'#fff'" />
                        </button>
                    </div>
                    
                </div>
                <div v-else class="w-full flex flex-col items-start" >
                    <div
                    class="w-full flex flex-col items-start text-left" :class="typePer == 'monthly' || typePer == 'yearly' ? 'opacity-40' : ''">
                    <span class="text-xl my-5">{{ $t('Meetix.DaysOfWeek') }}</span>
                    <VueMultiselect v-model="selectedWeekDays" :options="weekDays" :multiple="true"
                        :internal-search="false" :close-on-select="false" :placeholder="$t('Meetix.selectDay')" :showNoOptions="false"
                        :hideSelected="true" label="label" track-by="label" :disabled="typePer == 'monthly' || typePer == 'yearly'"
                        style="width: 100%; height: 100%; border: none;">
                        <template #tag="{ option, remove }"><span
                                class="custom__tag flex w-fit items-center gap-2"><span>{{ option.label
                                    }}</span><span class="custom__remove" @click="remove(option)">
                                    <IconCloseFill :size="20" :color="'#fff'" />
                                </span></span></template>
                    </VueMultiselect>
                </div>
                    
                </div>
                <!-- <div v-else class="w-full flex flex-col items-start">
                    <span class="text-xl my-5">{{ $t('Meetix.DaysOfWeek') }}</span>
                    <VueDatePicker v-model="datePer" :enable-time-picker="false" dark></VueDatePicker>
                </div> -->
                <div class="w-full flex flex-col items-start">
                </div>
            </div>
        </div>
        <div v-show="infoStep == 3" class="flex flex-col gap-5 h-[55vh] overflow-auto">
            <div class="flex justify-center text-left gap-10 mx-24">
                <div class="w-full flex flex-col items-start">
                    <span class="text-xl my-5">{{ $t('Meetix.ConflictSlot') }}</span>
                    <div class="w-full grid grid-cols-4 gap-4">
                        <span class="py-4 px-3 bg-red-700 text-center" v-for="(item, index) in periodical_availability"
                            :key="index">{{ formatDate(item.date) + " à " + item.availability[0].slot }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center gap-6">
            <button v-if="infoStep != 1" @click="returnPrevious"
                class="flex items-center border-2 border-white px-24 py-1 text-3xl font-semibold gap-10" :class="infoStep == 3 ? 'py-3' : ''">{{
                    $t('Meetix.Return').toUpperCase() }}
            </button>
            <button v-if="infoStep != 3" @click="nextStep"
                class="flex items-center border-2 border-white px-24 py-1 text-3xl font-semibold gap-10">{{
                    $t('Meetix.Next').toUpperCase() }}
                <iconArrowRight :size="50" :color="'#fff'" />
            </button>
        </div>
    </div>
</template>

<script>
import iconArrowRight from "@/components/icons/iconArrowRight.vue";
import iconPlus from "@/components/icons/iconPlus.vue";
import iconMinus from "@/components/icons/iconMinus.vue";
import VueMultiselect from 'vue-multiselect'
import { userService } from "@/_services";
import IconCloseFill from "./icons/iconCloseFill.vue";
import { notification } from 'ant-design-vue';
import moment from "moment";
export default {
    name: "LbrxInfoSelection",
    components: {
        iconArrowRight,
        iconPlus,
        iconMinus,
        VueMultiselect,
        IconCloseFill
    },
    props: {
        start: {
            required: false,
            value: Object
        },
        end: {
            required: false,
            value: Object
        },
        selectedRoom: {
            required: true,
            value: Object
        },
        date: {
            required: true,
            value: String
        }
    },
    data() {
        return {
            employeeId: "",
            meetReason: "",
            duration: 30,
            durationMeet: 0,
            participantNumber: 1,
            debounceTimer: null,
            users: [],
            fetching: false,
            selectedPart: [],
            allowPeriodical: false,
            infoStep: 1,
            typePer: "daily",
            datePer: null,
            selectedWeekDays: [],
            weekDays: [
                { label: this.$t('Meetix.Monday'), value: "Monday" },
                { label: this.$t('Meetix.Tuesday'), value: "Tuesday" },
                { label: this.$t('Meetix.Wednesday'), value: "Wednesday" },
                { label: this.$t('Meetix.Thursday'), value: "Thursday" },
                { label: this.$t('Meetix.Friday'), value: "Friday" }
            ],
            endPer: moment(this.date).add(1, 'days'),
            endPerMin: moment(this.date).add(1, 'days'),
            periodicity: 1,
            periodical_availability: [

            ]
        };
    },
    created() {
        this.durationMeet = this.duration;
        this.getSelectedRoomConfig();
    },
    computed: {
        displayDuration() {
            const minutes = this.durationMeet % 60;
            const hours = Math.floor(this.durationMeet / 60);
            if (hours != 0)
                return hours + " " + this.$t('Meetix.Hours') + " " + minutes + " " + this.$t('Meetix.Minutes');
            else
                return minutes + " " + this.$t('Meetix.Minutes');
        }
    },
    methods: {
        getSelectedRoomConfig() {
            if (this.selectedRoom) {
                this.durationMeet = this.selectedRoom.selectedDuration;
            }
        },
        nextStep() {
            if (this.allowPeriodical) {
                if (this.infoStep == 2) {
                    if ((this.typePer == 'daily' || this.typePer == 'weekly') && (this.selectedWeekDays.length == 0 || !this.endPer))
                        return;
                    if ((this.typePer == 'monthly' || this.typePer == 'yearly') && (!this.endPer))
                        return;
                    let reservation = {
                        employeeId: this.employeeId,
                        meetReason: this.meetReason,
                        durationMeet: this.durationMeet,
                        selectedPart: this.selectedPart,
                        allowPeriodical: true,
                        type: this.typePer,
                        periodicity: this.periodicity,
                        endDate: moment(this.endPer).format('YYYY-MM-DD')
                    };
                    if (this.typePer == 'daily' || this.typePer == 'weekly')
                        reservation.daysOfWeek = this.selectedWeekDays;
                    else if (this.typePer == 'monthly')
                        reservation.daysOfWeek = moment(this.datePer).format('DD');
                    else
                        reservation.daysOfWeek = moment(this.datePer).format('MM-DD');
                    this.$emit("nextStep", "equipmentSelection", reservation);
                } else {
                    if (this.employeeId.length != 0 && this.meetReason.length != 0 && this.selectedPart.length != 0)
                        this.infoStep = 2;
                }
            } else {
                if (this.employeeId.length != 0 && this.meetReason.length != 0 && this.selectedPart.length != 0) {
                    this.$emit("nextStep", "equipmentSelection",
                        {
                            employeeId: this.employeeId,
                            meetReason: this.meetReason,
                            durationMeet: this.durationMeet,
                            selectedPart: this.selectedPart,
                            allowPeriodical: false
                        });
                }
            }
        },
        manipulateDuration(type) {
            if (type == "add") {
                this.durationMeet += this.duration;
            } else if (type == "substract") {
                if (this.durationMeet > this.duration) {
                    this.durationMeet -= this.duration;
                }
            }
        },
        manipulateParticipants(type) {
            if (type == "add") {
                this.participantNumber += 1;
            } else if (type == "substract") {
                if (this.participantNumber > 1) {
                    this.participantNumber -= 1;
                }
            }
        },
        manipulatePeriodicity(type) {
            if (this.typePer != 'daily' || this.typePer != 'weekly') {
                if (type == "add") {
                    this.periodicity += 1;
                } else if (type == "substract") {
                    if (this.periodicity > 1) {
                        this.periodicity -= 1;
                    }
                }
            }
        },
        fetchUser(value) {
            this.users = [];
            this.fetching = true;


            //clear previous timer
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }


            //fetch users 

            this.debounceTimer = setTimeout(() => {
                userService.getAll("?per_page=8&keys=first_name&values=" + value)
                    .then((res) => {
                        const data = res.data.data.map((user) => ({
                            label: user.first_name + " " + user.last_name,
                            value: user.id,
                        }));

                        this.users = data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log('error fetching users', error);
                        this.showErrorNotification(this.$t('Meetix.NotFetchUsers'));
                    });
            }, 500);
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotAvailable(periodical_availability) {
            this.periodical_availability = periodical_availability.filter((el) => {
                if (el.availability[0].active == 0)
                    return el;
            });
            this.infoStep = 3;
        },
        formatDate(date) {
            return moment(date).locale('fr').format('LL');
        },
        returnPrevious() {
            if(this.infoStep > 1)
                this.infoStep -= 1;
        },
        changeTypePer() {
            if(this.typePer == 'monthly' || this.typePer == 'yearly') {
                this.selectedWeekDays = [];
            } else {
                this.periodicity = 1;
            }
        }
    }
};
</script>
<style>
.custom__tag {
    background-color: darkgrey;
    color: #272727;
    padding: 3px 5px;
    margin: 1px 5px;
    border-radius: 5px;
    display: flex;
}

select {
  border-right: 13px solid transparent
}

.multiselect__tags .multiselect__spinner {
    color: aqua;
    background-color: aquamarine;
}

.multiselect__tags {
    background-color: #272727 !important;
    width: 100%;
    /* height: 4rem; */
}

.multiselect__tags input {
    background-color: #272727 !important;
    height: 4rem;
    outline: none;
    display: block;
    font-size: 15px;
    padding: 0 10px;
}

.multiselect__tags .multiselect__placeholder {
    line-height: 4rem;
    padding: 0 10px;
}

.multiselect__tags-wrap {
    background-color: #272727 !important;
    background: #272727;
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
}

.multiselect__select {
    background-color: #272727 !important;
    background: #272727;
}

.multiselect__content-wrapper {
    background-color: #272727 !important;
    background: #272727;
    padding: 0 10px;
}

.multiselect__content-wrapper .multiselect__content .multiselect__element {
    padding: 5px 0;
}
</style>
<style scoped>
input {
    font-size: 30px;
}

p {
    font-size: 30px;
}
</style>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #fbb900;
}

input:focus+.slider {
    box-shadow: 0 0 1px #fbb900;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>