<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 2.66666V5.33332M8 2.66666V5.33332" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.994 17.3333H16.006M15.994 22.6666H16.006M21.3214 17.3333H21.3334M10.6667 17.3333H10.6786M10.6667 22.6666H10.6786" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.66669 10.6667H27.3334" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.33334 16.3243C3.33334 10.5146 3.33334 7.60971 5.00283 5.80486C6.67233 4 9.35933 4 14.7333 4H17.2667C22.6407 4 25.3277 4 26.9972 5.80486C28.6667 7.60971 28.6667 10.5146 28.6667 16.3243V17.009C28.6667 22.8188 28.6667 25.7236 26.9972 27.5285C25.3277 29.3333 22.6407 29.3333 17.2667 29.3333H14.7333C9.35933 29.3333 6.67233 29.3333 5.00283 27.5285C3.33334 25.7236 3.33334 22.8188 3.33334 17.009V16.3243Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 10.6667H28" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'iconCalendar',
  props: {
    size: Number,
    color: String
  }
}
</script>