import { authHeader, ApiConfigs } from "../_helpers";
// import store from '@/store';

export const roomService = {
    getAvailabilitySlots,
    getById,
    getAvailability,
    getAll,
    getRoomAvailabilityPeriod
};

function getAvailabilitySlots(payload: object) {
    const requestOptions = {
        method: "POST",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.room.getAvailabilitySlots}`, requestOptions)
        .then(
            handleResponse
        );
}

function getById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.room.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAvailability(payload: object) {
    const requestOptions = {
        method: "POST",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.room.getAvailability}`, requestOptions)
        .then(
            handleResponse
        );
}

function getRoomAvailabilityPeriod(payload: object) {
    const requestOptions = {
        method: "POST",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.room.roomsAvailabilityPeriod}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAll(param: any = "") {
    const requestOptions = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.room.getAll + param}`, requestOptions)
        .then(
            handleResponse
        );
}


function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("device");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}
