<template>
    <svg :width="size" :height="size" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27 9V45M45 27H9" :stroke="color" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'iconPlus',
  props: {
    size: Number,
    color: String
  }
}
</script>