<template>
   <svg :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" stroke="currentColor">
<path d="M5.66644 17.9135C4.2444 15.434 3.55777 13.4092 3.14376 11.3568C2.53143 8.32136 3.93272 5.35621 6.25407 3.46421C7.23517 2.66457 8.35985 2.93778 8.94 3.9786L10.2498 6.32836C11.2879 8.19085 11.807 9.12209 11.7041 10.1094C11.6011 11.0967 10.9011 11.9008 9.50096 13.509L5.66644 17.9135ZM5.66644 17.9135C8.54476 22.9324 13.0618 27.4519 18.0865 30.3336M18.0865 30.3336C20.566 31.7556 22.5908 32.4422 24.6432 32.8562C27.6786 33.4686 30.6438 32.0673 32.5358 29.7459C33.3354 28.7648 33.0622 27.6402 32.0214 27.06L29.6716 25.7502C27.8092 24.7121 26.8779 24.193 25.8906 24.2959C24.9033 24.3989 24.0992 25.0989 22.491 26.499L18.0865 30.3336Z" :stroke="color" stroke-width="2.5" stroke-linejoin="round"/>
<path d="M18 10.5H19.2857C19.8918 10.5 20.1949 10.5 20.3831 10.6831C20.5714 10.8661 20.5714 11.1607 20.5714 11.75C20.5714 12.9285 20.5714 13.5178 20.1949 13.8839C19.9085 14.1623 19.4893 14.229 18.7717 14.245C18.4037 14.2532 18.2197 14.2573 18.1099 14.3665C18 14.4758 18 14.6506 18 15V16.75C18 17.3393 18 17.6339 18.1883 17.8169C18.3766 18 18.6796 18 19.2857 18H20.5714M27 10.5V14.25M27 14.25H24.8143C24.3294 14.25 24.087 14.25 23.9363 14.1036C23.7857 13.9571 23.7857 13.7214 23.7857 13.25V10.5M27 14.25V18" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 6.45776C15.1335 6.30778 15.2722 6.16075 15.416 6.01693C19.4386 1.99436 25.9605 1.99436 29.9831 6.01693C34.0056 10.0395 34.0056 16.5614 29.9831 20.584C29.8392 20.7278 29.6922 20.8665 29.5422 21" :stroke="color" stroke-width="2.5" stroke-linecap="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconPhone',
  props: {
    size: Number,
    color: String
  }
}
</script>