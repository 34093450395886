<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9999 7.99976C19.9999 7.99976 12 13.8917 12 15.9998C12 18.108 20 23.9998 20 23.9998" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconArrowLeft2',
  props: {
    size: Number,
    color: String
  }
}
</script>