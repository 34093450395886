<template>
    <div class="flex-grow flex flex-col relative">
        <div class="w-full py-2 px-5 text-left text-2xl bg-[#575757] text-white">
            <span>{{ $t('Meetix.Calendar').toUpperCase() }}</span>
        </div>
        <div class="bg-[#3D3D3D] h-full w-full">
            <LbrxCalendarWeek v-if="displayCalendar" :weekDates="weekDates" :timeSlots="timeSlots" :data="reservations"
                :duration="duration" @toggleWeek="toggleWeek" />
        </div>
        <LbrxLoader v-if="loading" :color="'#FBB601'" />
    </div>
</template>

<script>
import moment from 'moment';
import LbrxCalendarWeek from "@/components/LbrxCalendarWeek"
import LbrxLoader from "@/components/LbrxLoader"
import { reservationService } from '@/_services';
export default {
    name: "calendarIndex",
    components: {
        LbrxCalendarWeek,
        LbrxLoader
    },
    data() {
        return {
            loading: false,
            themeTemplate: {},
            weekDates: [],
            timeSlots: [],
            startTime: "9:00",
            endTime: "17:00",
            reservations: [],
            duration: 30,
            displayCalendar: false,
            weeksAhead: 0,
            device: {},
            deviceConfig: {}
        };
    },
    created() {
        if (localStorage.getItem('selectedRoom')) {
            localStorage.removeItem('selectedRoom');
            location.reload();
        }
        if (localStorage.getItem('themeTemplate')) {
            this.themeTemplate = JSON.parse(localStorage.getItem('themeTemplate'));
        }
        if (localStorage.getItem('device')) {
            this.device = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.deviceConfig = JSON.parse(this.device.config);
            }
        }
        this.getWeekDates();
    },
    mounted() {
        this.generateTimeSlots();
        this.getAllReservations();
    },
    methods: {
        getWeekDates() {
            let currentDate = moment().locale(this.$i18n.locale);
            let startOfWeek = currentDate.clone().startOf('isoWeek');
            // Get the end of the week (Friday)
            let endOfWeek = currentDate.clone().startOf('isoWeek').add(4, 'days');

            // Iterate from Monday to Friday and push the dates into the array
            for (let date = startOfWeek; date.isSameOrBefore(endOfWeek); date.add(1, 'days')) {
                this.weekDates.push({ day: date.format('dddd'), date: date.format('DD MMMM'), completeFormat: date.format('YYYY-MM-DD') });
            }
        },
        getNextWeekDays(weeksOffset) {
            this.weeksAhead += weeksOffset;
            this.weekDates = []
            const days = [];
            const today = moment().locale(this.$i18n.locale);

            // Start from the next Monday
            const nextMonday = today.clone().startOf('isoWeek').add(1, 'weeks').add(this.weeksAhead - 1, 'weeks');

            for (let i = 0; i < 5; i++) {
                this.weekDates.push({day: nextMonday.clone().add(i, 'days').format('dddd'), date: nextMonday.clone().add(i, 'days').format('DD MMMM'),completeFormat: nextMonday.clone().add(i, 'days').format('YYYY-MM-DD')});
            }

            this.getAllReservations();
        },
        toggleWeek(date) {
            if(date == 'next')
                this.getNextWeekDays(1);
            else
                this.getNextWeekDays(-1);
        },
        generateTimeSlots() {
            // Define start and end times
            let startTime = moment().set({ hour: 9, minute: 0, second: 0 });
            let endTime = moment().set({ hour: 17, minute: 30, second: 0 });

            // Generate time slots with a difference of 30 minutes
            while (startTime.isBefore(endTime)) {
                // Push the time formatted as 'HH:mm' into the array
                this.timeSlots.push(startTime.format('HH:mm'));
                // Add 30 minutes to the current time
                startTime.add(this.duration, 'minutes');
            }
        },
        getAllReservations() {
            this.loading = true;
            reservationService.getAll(`?pagination=off&keys=room_id&values=${this.deviceConfig.room_id}&start_date=${this.weekDates[0].completeFormat}&end_date=${this.weekDates[4].completeFormat}`).then((res) => {
                this.reservations = res.data;
                // for (const el of this.reservations) {
                //     console.log('rrrrr', el)
                // }
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.loading = false;
                this.displayCalendar = true;
            });
        }
    }
};
</script>

<style scoped></style>