<template>
    <div v-if="status == 'available'"
        class="flex flex-col items-center justify-around h-full gap-10 px-12 pt-12 pb-9 text-white"
        :class="getStyleComputed">
        <div class="flex flex-col gap-4">
            <span class="text-3xl">{{ formattedDateTime }}</span>
            <span class="text-5xl font-semibold tracking-wider">{{ $t('Meetix.Available').toUpperCase() }}</span>
        </div>
        <div @click="goBooking" class="flex w-fit mx-auto bg-[#2F6F48] rounded-full p-9">
            <iconPlus :size="150" :color="'#fff'" />
        </div>
        <div class="pb-6">
            <span class="text-4xl font-semibold tracking-wider">{{ $t('Meetix.BookNow') }}</span>
        </div>
    </div>

    <div v-if="status == 'occupied'"
        class="flex flex-col items-center justify-around h-full gap-10 px-12 pt-12 pb-9 text-white"
        :class="getStyleComputed">
        <div class="flex flex-col gap-4">
            <span class="text-3xl">{{ formattedDateTime }}</span>
            <span class="text-5xl font-semibold tracking-wider">{{ $t('Meetix.Occupied').toUpperCase() }}</span>
        </div>


        <LbrxCountdown color="#fff" v-model="startCounter" @counter-ended="counterEnded" :timer="diffTime"
            :room="room" :diffTime="diffTime">
        </LbrxCountdown>
        <!-- <vue-countdown class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    :time="room.diffTime * 60 * 1000" :transform="transformSlotProps" v-slot="{ minutes, seconds }">
                    {{ minutes }}:{{ seconds }}
                </vue-countdown> -->


        <div class="bg-[#02020233] flex items-center justify-center gap-16 px-12 text-xl w-full">
            <div class="flex flex-col text-left">
                <span class="text-3xl text-nowrap">{{ $t('Meetix.MeetCurrent') }}</span>
                <div class="flex flex-col gap-1 py-4 text-[#E7E7E7]">
                    <div class="flex items-center gap-4">
                        <span>{{ getHours(reservation.start) }}</span>
                        <iconArrowRight :size="20" :color="'#fff'" />
                        <span>{{ getHours(reservation.end) }}</span>
                    </div>
                    <span class="text-2xl font-semibold text-white">{{ reservation.motif }}</span>
                    <span>{{ reservation.user?.first_name }}</span>
                </div>
            </div>
            <div>
                <iconUsers :size="200" :color="'#fff'" />
            </div>
        </div>
    </div>

    <div v-else class="flex flex-col items-center justify-center h-full gap-10 px-12 pt-12 pb-9 text-white"
        :class="getStyleComputed">
        <div class="flex flex-col gap-4">
            <span class="text-3xl">{{ formattedDateTime }}</span>
            <span class="text-5xl font-semibold tracking-wider">{{ $t('Meetix.Onhold').toUpperCase() }}</span>
        </div>
        <div class="flex w-fit mx-auto">
            <iconClock :size="160" :color="'#fff'" />
        </div>
        <div class="flex flex-col pb-6 gap-3">
            <span class="text-3xl tracking-wider">{{ $t('Meetix.BeginIn').toUpperCase() }}</span>
            <span class="text-5xl font-semibold tracking-wider">{{ diffTime + " " + $t('Meetix.Minutes') }}</span>
        </div>
    </div>
</template>

<script>
import iconPlus from "@/components/icons/iconPlus.vue";
import iconUsers from "@/components/icons/iconUsers.vue";
import iconArrowRight from "@/components/icons/iconArrowRight.vue";
import iconClock from "@/components/icons/iconClock.vue";
import VueCountdown from '@chenfengyuan/vue-countdown';
import LbrxCountdown from "@/components/LbrxCountdown.vue";
import moment from "moment/moment";
import router from "@/router";
export default {
    name: "LbrxRoomStatus",
    components: {
        iconPlus,
        iconUsers,
        iconArrowRight,
        iconClock,
        // VueCountdown,
        LbrxCountdown,
    },
    props: {
        status: {
            required: true,
            value: String,
            default: "available"
        },
        room: {
            required: true,
            value: Object
        },
        reservation: {
            required: true,
            value: Object
        },
        diffTime: {
            required: true,
            value: String
        }
    },
    data() {
        return {
            currentDateTime: moment(),
            intervalId: null,
            startCounter: true,
            timer: 60,
            // actualTime: moment().locale(this.$i18n.locale).format("HH:mm dddd, Do MMMM YYYY")
        };
    },
    computed: {
        getStyleComputed() {
            return this.status == "available" ? "gradient-img-success" : this.status == "occupied" ? "gradient-img-occupied" : "gradient-img-onhold";
        },
        formattedDateTime() {
            return this.currentDateTime.locale(this.$i18n.locale).format('HH:mm dddd, Do MMMM YYYY');
        }
    },
    created() {
        // 
    },
    mounted() {
        this.updateDateTime();
        this.synchronizeUpdate();
    },
    methods: {
        counterEnded() {
            this.startCounter = false
            // this.codeChances -= 1
            // if (this.codeChances > 0) {
            //     this.generateAuthCode()
            //     setTimeout(() => {
            //         this.startCounter = true
            //     }, 1)
            // }
        },
        goBooking() {
            router.push("/booking");
        },
        updateDateTime() {
            this.currentDateTime = moment();
            this.$emit("getRoomById");
            this.$emit("getAllReservations");
        },
        synchronizeUpdate() {
            const now = moment();
            const nextMinute = moment().startOf('minute').add(1, 'minute');
            const msUntilNextMinute = nextMinute.diff(now);

            setTimeout(() => {
                this.updateDateTime();
                this.intervalId = setInterval(this.updateDateTime, 60000);
            }, msUntilNextMinute);
        },
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
        getHours(dateTime) {
            return moment(dateTime).locale(this.$i18n.locale).format('HH:mm');
        }
    },
    beforeUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
};
</script>

<style scoped>
.gradient-img-success {
    background: linear-gradient(180deg, rgba(77, 173, 75, 0.89) 0%, rgba(56, 176, 46, 0.89) 100%);
}

.gradient-img-occupied {
    background: linear-gradient(180deg, rgba(110, 5, 0, 0.89) 0%, rgba(165, 10, 4, 0.89) 100%);
}

.gradient-img-onhold {
    background: linear-gradient(180deg, rgba(251, 185, 0, 0.89) 0%, rgba(223, 94, 0, 0.89) 100%);
}
</style>