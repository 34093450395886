<template>
    <div v-if="active" class="fixed w-screen h-screen flex justify-center items-center z-50">

        <div class="bg-[#3D3D3D] relative z-30 text-white px-6 py-8">
            <span class="absolute right-6 top-2 scale-[240%] " @click="toggleModal">&times;</span>
            <div class="flex flex-col gap-3">
                <span class="text-3xl">{{ $t('Meetix.Logout2') }}</span>
                <span>{{ $t('Meetix.InputAd') }}</span>
                <input id="inputBar" v-model="matricule" class="mt-4 py-1 text-[#3D3D3D] text-center text-3xl border-2 border-b-gray-300 font-extrabold"
                >
                <button @click="logout" class="bg-[#FBB601] py-3">{{ $t('Meetix.Confirm') }}</button>
            </div>
        </div>
        <div @click="toggleModal" class="fixed h-screen w-screen top-0 left-0 backdrop-blur-sm bg-[#1e1e1e80]"></div>
    </div>
</template>

<script>
export default {
    name: "LbrxLogoutPopup",
    components: {
    },
    props: {
        active: {
            required: true,
            value: Boolean
        }
    },
    data() {
        return {
            matricule: ""
        };
    },
    methods: {
        toggleModal() {
            this.matricule = "";
            this.$emit("toggleModal");
        },
        logout() {
            if(this.matricule.length != 0) {
                this.$emit("logout");
            }
        }
    }
};
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: aqua;

}
</style>