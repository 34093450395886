<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0001 8C12.0001 8 20 13.8919 20 16.0001C20 18.1082 12 24 12 24" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconArrowRight2',
  props: {
    size: Number,
    color: String
  }
}
</script>