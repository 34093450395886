<template>
    <svg :width="size" :height="size" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 26.4375C22.3896 29.2154 20.0746 31.5741 16.9735 31.4982C16.252 31.4806 15.3602 31.229 13.5767 30.726C9.28442 29.5153 5.55833 27.4805 4.66433 22.9223C4.5 22.0844 4.5 21.1416 4.5 19.2559L4.5 16.7441C4.5 14.8584 4.5 13.9156 4.66433 13.0777C5.55833 8.51948 9.28442 6.48474 13.5767 5.27404C15.3602 4.77097 16.252 4.51943 16.9735 4.50178C20.0746 4.42591 22.3896 6.78461 22.5 9.56251" :stroke="color" stroke-width="3.5" stroke-linecap="round"/>
<path d="M31.5 18H15M31.5 18C31.5 16.9497 28.5085 14.9873 27.75 14.25M31.5 18C31.5 19.0503 28.5085 21.0127 27.75 21.75" :stroke="color" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'iconLogout',
  props: {
    size: Number,
    color: String
  }
}
</script>