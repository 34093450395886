<template>
    <svg :width="size" :height="size" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0139 3V7.69829C30.0139 8.13908 29.4627 8.33863 29.1805 8C26.4339 4.93125 22.4425 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33C26.2843 33 33 26.2843 33 18" :stroke="color" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'iconRefresh',
  props: {
    size: Number,
    color: String
  }
}
</script>